import React from 'react';
import { Select } from '@chakra-ui/react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { CaretDoubleLeft, CaretDoubleRight, CaretLeft, CaretRight } from 'phosphor-react';
import { IPagination } from '../../types/shared';

interface PaginatorProps {
  pagination: IPagination;
}

export const Paginator = ({ pagination }: PaginatorProps) => {
  const [filters, setFilters] = useSearchParams();

  const updateFilters = (newParams: Record<string, string>) => {
    const currentParams = Object.fromEntries(filters.entries());
    setFilters(createSearchParams({ ...currentParams, ...newParams }));
  };

  return (
    <div className="flex justify-end space-x-2 pt-4">
      <div className="self-center">Linhas</div>

      <Select
        w={70}
        h="32px"
        value={filters.get('size') ?? '10'} // Usa '10' como valor padrão se 'size' for undefined
        onChange={e => {
          updateFilters({ page: '1', size: e.target.value });
        }}
      >
        {[10, 20, 30, 40, 50].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </Select>

      <div className="text-muted-foreground self-center text-sm">
        Página(s) {pagination.page} de {pagination.pages}
      </div>

      <button
        type="button"
        aria-label="primeira"
        className="flex items-center justify-center rounded-md border border-border bg-body px-1 hover:opacity-80 disabled:opacity-50"
        onClick={pagination.previous_page !== null ? () => updateFilters({ page: '1' }) : undefined}
        disabled={pagination.previous_page === null}
      >
        <CaretDoubleLeft size={16} weight="bold" />
      </button>

      <button
        type="button"
        aria-label="anterior"
        className="flex items-center justify-center rounded-md border border-border bg-body px-1 hover:opacity-80 disabled:opacity-50"
        onClick={
          pagination.previous_page !== null
            ? () =>
                updateFilters({
                  page: pagination.previous_page ? pagination.previous_page.toString() : '1',
                })
            : undefined
        }
        disabled={pagination.previous_page === null}
      >
        <CaretLeft size={16} weight="bold" />
      </button>

      <button
        type="button"
        aria-label="proxima"
        className="flex items-center justify-center rounded-md border border-border bg-body px-1 hover:opacity-80 disabled:opacity-50"
        onClick={
          pagination.next_page !== null
            ? () =>
                updateFilters({
                  page: pagination.next_page ? pagination.next_page.toString() : '1',
                })
            : undefined
        }
        disabled={pagination.next_page === null}
      >
        <CaretRight size={16} weight="bold" />
      </button>

      <button
        type="button"
        aria-label="ultima"
        className="flex items-center justify-center rounded-md border border-border bg-body px-1 hover:opacity-80 disabled:opacity-50"
        onClick={
          pagination.next_page !== null
            ? () => updateFilters({ page: pagination.pages.toString() })
            : undefined
        }
        disabled={pagination.next_page === null}
      >
        <CaretDoubleRight size={16} weight="bold" />
      </button>
    </div>
  );
};
