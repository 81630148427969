/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { Spinner, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useQuery } from '@tanstack/react-query';
import { createSearchParams, useParams, useSearchParams } from 'react-router-dom';
import { PageBox } from '../../components/pageBox';
import { ReturnButton } from '../../components/buttons/ReturnButton';
// import { InputForm } from '../../components/inputs/forms/InputForm';
import { FormBox } from '../../components/formBox';
import { IDataNoPagination } from '../../types/shared';
import { IBreak, useCompanies } from '../../hooks/useCompanies';

export function BreaksPage() {
  const { getQuebrasWithId } = useCompanies();
  const { itemId } = useParams();

  const [filters, setFilters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '30',
      search: '',
      companyId: itemId || '',
    }),
  );

  const filtersFormMethods = useForm<{ search: string; companyId: any }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.get('search') ?? '',
    },
  });

  const debounced = useDebounceCallback(values => {
    setFilters(prevFilters =>
      createSearchParams({
        ...prevFilters,
        search: values.search,
        companyId: values.companies?.external_id || '',
        page: '1',
      }),
    );
  }, 500);

  useEffect(() => {
    const subscription = filtersFormMethods.watch(debounced);
    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch]);

  // const queryKeyQuebrasTotatais = ['getQuebrasTotais', { page: 1, size: 30, search: '' }];

  const { data, isFetching: isFetchingCompany } = useQuery<IDataNoPagination<IBreak>>({
    queryKey: ['CompaniesSelect', Object.fromEntries([...Array.from(filters)])],
    queryFn: getQuebrasWithId,
  });

  // const { data: quebrasTotais, isFetching: isFetchingQuebrasTotais } = useQuery<any>({
  //   queryKey: queryKeyQuebrasTotatais,
  //   queryFn: getQuebrasTotais,
  // });

  const dataCompanies = data?.data;
  return (
    <PageBox>
      <div className="flex flex-col gap-2">
        <ReturnButton label="Voltar" />
        <div className="flex flex-col items-center gap-3 p-5 lg:flex-row">
          <div className="h-full">
            <div className="flex items-center justify-center rounded-full bg-[#FFE0EB] p-3">
              <IoCloseCircleOutline color="#FF82AC" className="size-12" />
            </div>
          </div>
          <div className="flex w-full flex-col gap-1">
            <Text fontWeight="bold" fontSize="16px">
              Quebras
            </Text>
          </div>
        </div>

        <FormBox>
          <div className="flex flex-col gap-4  p-4">
            {/* <div className="flex justify-between gap-2">
              <FormProvider {...filtersFormMethods}>
                <form className="flex gap-4">
                  <InputForm
                    name="search"
                    placeholder="Buscar"
                    boxShadow="0px 2px 4px #1E293B0D"
                    controlMb={0}
                  />
                </form>
              </FormProvider>
            </div> */}
            {isFetchingCompany ? (
              <div className="flex flex-col items-center gap-4">
                <Spinner size="lg" color="primary" />
                <span className="text-xl font-semibold">Carregando...</span>
              </div>
            ) : dataCompanies && Object.keys(dataCompanies).length === 0 && !isFetchingCompany ? (
              <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
            ) : (
              <div className="grid grid-flow-row grid-cols-1 items-center justify-between gap-2 text-center lg:grid-cols-2">
                <div className="flex gap-4 rounded-lg border border-border bg-body px-8 py-4 shadow-lg lg:flex-col">
                  <div className="flex flex-col gap-2">
                    <span className="text-4xl font-bold">NFE</span>
                    <div>
                      {dataCompanies?.nfe?.map(item => (
                        <span className="text-sm text-[#718EBF]">{`${item}, `}</span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 rounded-lg border border-border bg-body px-8 py-4 text-center shadow-lg lg:flex-col">
                  <div className="flex flex-col gap-2">
                    <span className="text-4xl font-bold">NFSE</span>
                    <div>
                      {dataCompanies?.nfse?.map(item => (
                        <span className="text-sm text-[#718EBF]">{`${item}, `}</span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 rounded-lg border border-border bg-body px-8 py-4 text-center shadow-lg lg:flex-col">
                  <div className="flex flex-col gap-2">
                    <span className="text-4xl font-bold">NFCE</span>
                    <div>
                      {dataCompanies?.nfce?.map(item => (
                        <span className="text-sm text-[#718EBF]">{`${item}, `}</span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </FormBox>
      </div>
    </PageBox>
  );
}
