import React from 'react';
import { Box, Flex, Stack, UseDisclosureReturn, Text } from '@chakra-ui/react';
import { List } from 'phosphor-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PiIntersectSquareDuotone } from 'react-icons/pi';
import { RiFileList3Line } from 'react-icons/ri';
import { FiDivideSquare } from 'react-icons/fi';
import { IoDocumentTextOutline } from 'react-icons/io5';
import Options from './Options';
import { NavItem } from '../navItem';

interface SidebarProps {
  isOpen: boolean;
  onToggle: UseDisclosureReturn['onToggle'];
}

const Sidebar = ({ isOpen, onToggle }: SidebarProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const OpenRoutes = [
    {
      name: 'INTEGRAÇÃO',
      path: '/modulesHome/integration',
      icon: <PiIntersectSquareDuotone color="#0A4E70" className="size-5" />,
      bgColor: '#7AD8FF',
    },
    {
      name: 'FISCAL',
      path: '/modulesHome/supervisor',
      icon: <RiFileList3Line color="#008CCC" className="size-5" />,
      bgColor: '#DFF3FF',
    },
    {
      name: 'CONCILIAÇÃO CONTÁBIL',
      path: '/modulesHome/accountingConciliation',
      icon: <FiDivideSquare color="#F2994A" className="size-5" />,
      bgColor: '#FFE1C6',
    },
    {
      name: 'FOLHA DE PAGAMENTO',
      path: '/modulesHome/integration/billingAnalysis',
      icon: <IoDocumentTextOutline color="#219653" className="size-5" />,
      bgColor: '#ABEDC6',
    },
  ];
  return (
    <Stack
      position="fixed"
      transition="0.5s ease"
      bg="white"
      borderRight="1px solid"
      borderRightColor="border"
      w={isOpen ? '216px' : '64px'}
      h="100%"
      overflowY="auto"
      overflowX="hidden"
      // justifyContent="space-between"
      userSelect="none"
    >
      <Box>
        <div className="hidden md:block">
          <NavItem href="Menu" onClick={onToggle} icon={List} openMenu={isOpen}>
            Menu
          </NavItem>
        </div>

        <Options isOpen={isOpen} />
      </Box>
      <div className={`flex flex-col gap-2 ${isOpen ? 'px-4' : 'px-2'} pt-8`}>
        {OpenRoutes.map(item => (
          <div>
            <Flex
              className={`flex w-full gap-2 rounded-full ${item.path === location.pathname && isOpen ? 'bg-[#DFF3FF]' : ''} cursor-pointer`}
              onClick={() => navigate(item.path)}
            >
              <div>
                <div
                  className={`flex items-center justify-center rounded-full bg-[${item.bgColor}] p-3 `}
                >
                  {item.icon}
                </div>
              </div>
              <div className="flex w-full flex-col justify-center gap-1">
                {isOpen && (
                  <Text className="text-[12px] font-medium text-[#0F172A]">{item.name}</Text>
                )}
              </div>
            </Flex>
          </div>
        ))}
      </div>
    </Stack>
  );
};

export default Sidebar;
