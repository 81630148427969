/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useQuery } from '@tanstack/react-query';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { NotePencil } from 'phosphor-react';
import { ReturnButton } from '../../../../components/buttons/ReturnButton';
import { InputForm } from '../../../../components/inputs/forms/InputForm';
import { FormBox } from '../../../../components/formBox';
import { IData, IPagination } from '../../../../types/shared';
import { ISaidaImport, useCompanies } from '../../../../hooks/useCompanies';
import { Paginator } from '../../../../components/paginator';
import { DateToolbarFilter } from '../../../../components/DateToolbarFilter';
import { PageBox } from '../../../../components/pageBox';

export function ModulesHomeIntegrationManualPostingsPage() {
  const { getCompaniesSaidasImports, getSaidasImportsTotais } = useCompanies();

  const [filters, setFilters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '10',
      search: '',
      date_start: moment().subtract(6, 'days').format('YYYY-MM-DD'), // Convertendo para string
      date_end: moment().format('YYYY-MM-DD'), // Convertendo para string
    }),
  );

  const filtersFormMethods = useForm<{
    search: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.get('search') ?? '',
    },
  });

  const debounced = useDebounceCallback(
    values =>
      setFilters(
        createSearchParams({
          ...Object.fromEntries(filters),
          search: values.search,
          page: '1',
        }),
      ),
    500,
  );

  useEffect(() => {
    const subscription = filtersFormMethods.watch(debounced);
    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch]);

  const { data: dataCompaniesList, isFetching: isFetchingCompanyList } = useQuery<
    IData<ISaidaImport[]>
  >({
    queryKey: ['CompaniesList', Object.fromEntries([...Array.from(filters)])],
    queryFn: getCompaniesSaidasImports,
  });

  const { items: companiesList, ...pagination } = dataCompaniesList?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  const queryKeyTotais = [
    'getTotais',
    {
      date_start: filters.get('date_start'),
      date_end: filters.get('date_end'),
    },
  ];

  const { data: totais, isFetching: isFetchingTotais } = useQuery<any>({
    queryKey: queryKeyTotais,
    queryFn: getSaidasImportsTotais,
  });

  return (
    <PageBox>
      <div className="flex flex-col items-center justify-between gap-3 py-5 lg:flex-row">
        <div className="flex content-start md:w-1/3 lg:content-center ">
          <ReturnButton previousPage="modulesHome/integration" />

          <div className="h-full">
            <div className="flex items-center justify-center rounded-full bg-[#D9F1F1] p-3">
              <NotePencil color="#37474F" className="size-8" />
            </div>
          </div>
          <div className="flex w-full flex-col justify-center gap-1 pl-2">
            <Text fontWeight="bold" fontSize="16px">
              Lançamentos Manuais
            </Text>
          </div>
        </div>

        <div className="mb-5 grid grid-cols-1 gap-3 md:grid-cols-3">
          {isFetchingTotais ? (
            <div className="flex flex-col items-center gap-4">
              <Spinner size="lg" color="primary" />
              <span className="text-xl font-semibold">Carregando...</span>
            </div>
          ) : (
            <>
              <Flex
                className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5"
                // onClick={() => {
                //   const newFilters = new URLSearchParams(Object.fromEntries(filters));
                //   if (filters.get('situacao') === 'ok') {
                //     newFilters.delete('situacao'); // Remove o filtro se já estiver selecionado
                //   } else {
                //     newFilters.set('situacao', 'ok'); // Aplica o filtro
                //   }
                //   setFilters(newFilters);
                // }}
              >
                <div className="flex w-full flex-col ">
                  <Text fontSize="14px" color="#718EBF">
                    Total NFE
                  </Text>
                  <Text fontSize="18px" fontWeight="bold">
                    {totais?.quantity_nfe ?? 0}
                  </Text>
                </div>
              </Flex>

              <Flex
                className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5"
                // onClick={() => {
                //   const newFilters = new URLSearchParams(Object.fromEntries(filters));
                //   if (filters.get('situacao') === 'erro') {
                //     newFilters.delete('situacao'); // Remove o filtro se já estiver selecionado
                //   } else {
                //     newFilters.set('situacao', 'erro'); // Aplica o filtro
                //   }
                //   setFilters(newFilters);
                // }}
              >
                <div className="flex w-full flex-col ">
                  <Text fontSize="14px" color="#718EBF">
                    Total NFCE
                  </Text>
                  <Text fontSize="18px" fontWeight="bold">
                    {totais?.quantity_nfce ?? 0}
                  </Text>
                </div>
              </Flex>

              <Flex
                className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5"
                // onClick={() => {
                //   const newFilters = new URLSearchParams(Object.fromEntries(filters));
                //   if (filters.get('situacao') === 'erro') {
                //     newFilters.delete('situacao'); // Remove o filtro se já estiver selecionado
                //   } else {
                //     newFilters.set('situacao', 'erro'); // Aplica o filtro
                //   }
                //   setFilters(newFilters);
                // }}
              >
                <div className="flex w-full flex-col ">
                  <Text fontSize="14px" color="#718EBF">
                    Total NFSE
                  </Text>
                  <Text fontSize="18px" fontWeight="bold">
                    {totais?.quantity_nfse ?? 0}
                  </Text>
                </div>
              </Flex>
            </>
          )}
        </div>
      </div>

      <FormBox>
        <div className="flex flex-col gap-4  p-4">
          <div className="flex flex-col justify-between gap-2">
            <FormProvider {...filtersFormMethods}>
              <div className="flex flex-col justify-between gap-4 lg:flex-row">
                <div className="flex content-center items-center justify-center gap-3 pt-10">
                  <InputForm
                    name="search"
                    placeholder="Buscar por nome, cnpj, razão social"
                    boxShadow="0px 2px 4px #1E293B0D"
                    controlMb={0}
                  />
                </div>

                <div className="justify-end ">
                  <DateToolbarFilter setFilters={setFilters} filters={filters} showMonthYearOnly />
                </div>
              </div>
            </FormProvider>
          </div>
          {isFetchingCompanyList ? (
            <div className="flex flex-col items-center gap-4">
              <Spinner size="lg" color="primary" />
              <span className="text-xl font-semibold">Carregando...</span>
            </div>
          ) : companiesList?.length === 0 ? (
            <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
          ) : filters.get('search') && companiesList?.length === 0 && !isFetchingCompanyList ? (
            <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
          ) : (
            <div className="grid grid-flow-row grid-cols-1 items-center justify-between gap-2">
              {companiesList?.map(item => (
                <div className="flex gap-4 rounded-lg border border-border bg-body p-4 shadow-lg lg:flex-col">
                  <div className="grid grid-cols-1 gap-9 lg:grid-cols-2">
                    <div className="flex flex-col gap-2 ">
                      <span className="text-base font-bold">{item?.name}</span>
                      <span className="text-sm  text-[#718EBF]">{item?.origin}</span>
                    </div>
                    <div className="grid grid-cols-2 gap-2 lg:grid-cols-4">
                      <div className="flex flex-col gap-2 ">
                        <span className="text-base font-bold">NFE</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">{item.quantity_nfe ?? 0}</Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 ">
                        <span className="text-base font-bold">NFCE</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">{item.quantity_nfce ?? 0}</Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="text-base font-bold">NFSE</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">{item.quantity_nfse ?? 0}</Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <Paginator pagination={pagination} />
            </div>
          )}
        </div>
      </FormBox>
    </PageBox>
  );
}
