/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Button, Spinner, Text, Tooltip } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useQuery } from '@tanstack/react-query';
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineInsertPageBreak } from 'react-icons/md';
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { CiCircleCheck } from 'react-icons/ci';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { Package, WarningCircle } from 'phosphor-react';
import { ReturnButton } from '../../../../components/buttons/ReturnButton';
import { InputForm } from '../../../../components/inputs/forms/InputForm';
import { FormBox } from '../../../../components/formBox';
import SelectInputForm from '../../../../components/inputs/forms/SelectInputForm';
import { IData, IPagination, ISelect } from '../../../../types/shared';
import { ICompany, useCompanies } from '../../../../hooks/useCompanies';
import { IClient } from '../../../../hooks/useClients';
import { formatCNPJ, getFormattedLabelString, toCurrency } from '../../../../utils/FormatFunctions';
import { Paginator } from '../../../../components/paginator';
import { DateToolbarFilter } from '../../../../components/DateToolbarFilter';

export function ModulesHomeIntegrationBillingAnalysisPage() {
  const { getCompanies, getCompaniesFilters, getSituationsTotals } = useCompanies();
  const [showFilters, setShowFilters] = useState(false);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const searchParamStart = queryParams.get('date_start');
  const searchParamEnd = queryParams.get('date_end');

  const toggleFilters = () => setShowFilters(prev => !prev);
  const [filters, setFilters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '10',
      search: '',
      companyId: '',
      date_start: moment().startOf('month').format('YYYY-MM-DD'), // Convertendo para string
      date_end: moment().endOf('month').format('YYYY-MM-DD'), // Convertendo para string
      screen_data: 'faturamento',
    }),
  );

  const filtersFormMethods = useForm<{
    search: string;
    companyId: any;
    cliente: IClient;
    cidade: ISelect<string>;
    uf: ISelect<string>;
    grupo_de_empresas: ISelect<string>;
    tags: ISelect<string>;
    comercial: ISelect<string>;
    contabil: ISelect<string>;
    financeiro: ISelect<string>;
    contas_a_pagar: ISelect<string>;
    encerramento_anual: ISelect<string>;
    fiscal: ISelect<string>;
    imposto_de_renda: ISelect<string>;
    integracao_contabil: ISelect<string>;
    integracao_fiscal: ISelect<string>;
    pessoal: ISelect<string>;
    relacionamento_com_cliente: ISelect<string>;
    societario: ISelect<string>;
    situacao: 'ok' | 'aviso' | 'erro' | 'outro' | null;
  }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.get('search') ?? '',
    },
  });

  const debounced = useDebounceCallback(values => {
    const fields = {
      search: values.search,
      date_start: searchParamStart ?? filters.get('date_start') ?? '',
      date_end: searchParamEnd ?? filters.get('date_end') ?? '',
      id: values.companies?.external_id,
      cidade: values.cidade?.value,
      uf: values.uf?.value,
      grupo_de_empresas: values.grupo_de_empresas?.value,
      tags: values.tags?.value,
      comercial: values.comercial?.value,
      contabil: values.contabil?.value,
      financeiro: values.financeiro?.value,
      contas_a_pagar: values.contas_a_pagar?.value,
      encerramento_anual: values.encerramento_anual?.value,
      fiscal: values.fiscal?.value,
      imposto_de_renda: values.imposto_de_renda?.value,
      integracao_contabil: values.integracao_contabil?.value,
      integracao_fiscal: values.integracao_fiscal?.value,
      pessoal: values.pessoal?.value,
      relacionamento_com_cliente: values.relacionamento_com_cliente?.value,
      societario: values.societario?.value,
    };

    Object.entries(fields).forEach(([key, value]) => {
      if (value) filters.set(key, value);
      else filters.delete(key);
    });

    setFilters(filters);
  }, 500);

  useEffect(() => {
    const subscription = filtersFormMethods.watch(values => {
      debounced(values);
    });

    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch, searchParamStart, searchParamEnd]);

  const queryKeySituacoesTotais = [
    'getSituationsTotals',
    {
      date_start: (searchParamStart as any) ? (searchParamStart as any) : filters.get('date_start'),

      date_end: (searchParamEnd as any) ? (searchParamEnd as any) : filters.get('date_end'),
    },
  ];
  const { data: dataCompaniesSituacoes, isFetching: isFetchingSituacoesTotais } = useQuery<any>({
    queryKey: queryKeySituacoesTotais,
    queryFn: getSituationsTotals,
  });
  const situacoesTotais = dataCompaniesSituacoes;

  const queryKeyCompaniesFilters = ['CompaniesFilters'];
  const { data: CompaniesFiltersData } = useQuery({
    queryKey: queryKeyCompaniesFilters,
    queryFn: getCompaniesFilters,
  });

  const CompaniesFilters = CompaniesFiltersData;

  const { data: dataCompaniesSelect, isFetching: isFetchingCompanySelect } = useQuery<
    IData<ICompany[]>
  >({
    queryKey: ['CompaniesSelect', { page: 1, size: 30, search: '' }],
    queryFn: getCompanies,
  });

  const { data: dataCompaniesList, isFetching: isFetchingCompanyList } = useQuery<
    IData<ICompany[]>
  >({
    queryKey: ['CompaniesList', Object.fromEntries([...Array.from(filters)])],
    queryFn: getCompanies,
  });

  const { items: companies } = dataCompaniesSelect?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  const { items: companiesList, ...pagination } = dataCompaniesList?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="flex flex-col items-center justify-between gap-3 py-5 lg:flex-row">
        <div className="flex content-start md:w-1/3 lg:content-center ">
          <ReturnButton previousPage="modulesHome/integration" />

          <div className="h-full">
            <div className="flex items-center justify-center rounded-full bg-[#ABEDC6] p-3">
              <MdOutlineInsertPageBreak color="#219653" className="size-8" />
            </div>
          </div>
          <div className="flex w-full flex-col justify-center gap-1 pl-2">
            <Text fontWeight="bold" fontSize="16px">
              Análise de Faturamento
            </Text>
          </div>
        </div>

        <div className="mb-5 grid grid-cols-1 gap-3 md:grid-cols-4">
          {isFetchingSituacoesTotais ? (
            <div className="flex flex-col items-center gap-4">
              <Spinner size="lg" color="primary" />
              <span className="text-xl font-semibold">Carregando...</span>
            </div>
          ) : (
            <>
              <Tooltip
                label="Empresas onde a variação de faturamento em relação ao mês anterior está dentro de 20%."
                fontSize="md"
              >
                <div
                  className={`flex cursor-pointer items-center gap-3 rounded-3xl ${
                    filters.get('situacao') === 'ok' ? 'bg-[#DCFAF8]' : 'bg-body'
                  } p-5`}
                  onClick={() => {
                    const newFilters = new URLSearchParams(Object.fromEntries(filters));
                    if (filters.get('situacao') === 'ok') {
                      newFilters.delete('situacao'); // Remove o filtro se já estiver selecionado
                    } else {
                      newFilters.set('situacao', 'ok'); // Aplica o filtro
                    }
                    setFilters(newFilters);
                  }}
                >
                  <div className="flex h-14 w-16 items-center justify-center rounded-full bg-[#DCFAF8] p-3">
                    <CiCircleCheck color="#16DBCC" className="size-7" />
                  </div>

                  <div className="flex w-full flex-col">
                    <Text fontSize="14px" color="#718EBF">
                      Ok
                    </Text>
                    <Text fontSize="18px" fontWeight="bold">
                      {situacoesTotais?.ok}
                    </Text>
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                label="Empresas onde a variação de faturamento em relação ao mês anterior está entre 21% e 50%."
                fontSize="md"
              >
                <div
                  className={`flex cursor-pointer items-center gap-3 rounded-3xl ${filters.get('situacao') === 'aviso' ? 'bg-[#FFFECA]' : 'bg-body'} p-5`}
                  onClick={() => {
                    const newFilters = new URLSearchParams(Object.fromEntries(filters));
                    if (filters.get('situacao') === 'aviso') {
                      newFilters.delete('situacao'); // Remove o filtro se já estiver selecionado
                    } else {
                      newFilters.set('situacao', 'aviso'); // Aplica o filtro
                    }
                    setFilters(newFilters);
                  }}
                >
                  <div className="flex h-14 w-16 items-center justify-center rounded-full bg-[#FFFECA] p-3">
                    <WarningCircle color="#fb923c" className="size-7" />
                  </div>

                  <div className="flex w-full flex-col ">
                    <Text fontSize="14px" color="#718EBF">
                      Aviso
                    </Text>
                    <Text fontSize="18px" fontWeight="bold">
                      {situacoesTotais?.aviso}
                    </Text>
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                label="Empresas onde a variação de faturamento em relação ao mês anterior está acima de 50%."
                fontSize="md"
              >
                <div
                  className={`flex cursor-pointer items-center gap-3 rounded-3xl ${filters.get('situacao') === 'erro' ? 'bg-[#FFE0EB]' : 'bg-body'} p-5`}
                  onClick={() => {
                    const newFilters = new URLSearchParams(Object.fromEntries(filters));
                    if (filters.get('situacao') === 'erro') {
                      newFilters.delete('situacao'); // Remove o filtro se já estiver selecionado
                    } else {
                      newFilters.set('situacao', 'erro'); // Aplica o filtro
                    }
                    setFilters(newFilters);
                  }}
                >
                  <div className="flex h-14 w-16 items-center justify-center rounded-full bg-[#FFE0EB] p-3">
                    <IoCloseCircleOutline color="#FF82AC" className="size-7" />
                  </div>

                  <div className="flex w-full flex-col ">
                    <Text fontSize="14px" color="#718EBF">
                      Erro
                    </Text>
                    <Text fontSize="18px" fontWeight="bold">
                      {situacoesTotais?.erro}
                    </Text>
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                label="Empresas onde a variação de faturamento foi justificada."
                fontSize="md"
              >
                <div
                  className={`flex cursor-pointer items-center gap-3 rounded-3xl ${filters.get('situacao') === 'outro' ? 'bg-gray-200' : 'bg-body'} p-5`}
                  onClick={() => {
                    const newFilters = new URLSearchParams(Object.fromEntries(filters));
                    if (filters.get('situacao') === 'outro') {
                      newFilters.delete('situacao'); // Remove o filtro se já estiver selecionado
                    } else {
                      newFilters.set('situacao', 'outro'); // Aplica o filtro
                    }
                    setFilters(newFilters);
                  }}
                >
                  <div className="flex h-14 w-16 items-center justify-center rounded-full bg-gray-200 p-3">
                    <Package color="#4b5563" className="size-7" />
                  </div>

                  <div className="flex w-full flex-col ">
                    <Text fontSize="14px" color="#718EBF">
                      Outro
                    </Text>
                    <Text fontSize="18px" fontWeight="bold">
                      0
                    </Text>
                  </div>
                </div>
              </Tooltip>
            </>
          )}
        </div>
      </div>

      <FormBox>
        <div className="flex flex-col gap-4  p-4">
          <div className="flex flex-col justify-between gap-2">
            <FormProvider {...filtersFormMethods}>
              <div className="flex flex-col justify-between gap-4 lg:flex-row">
                <div className="flex content-center items-center justify-center gap-3 pt-10">
                  <InputForm
                    name="search"
                    placeholder="Buscar por nome, cnpj, razão social"
                    boxShadow="0px 2px 4px #1E293B0D"
                    controlMb={0}
                  />
                  <Button
                    onClick={toggleFilters}
                    rightIcon={
                      showFilters ? (
                        <MdKeyboardArrowUp size="25px" />
                      ) : (
                        <MdKeyboardArrowDown size="25px" />
                      )
                    }
                  >
                    {showFilters ? 'Esconder Filtros' : 'Mostrar Filtros'}
                  </Button>
                </div>

                <div className="justify-end ">
                  <DateToolbarFilter setFilters={setFilters} filters={filters} showMonthYearOnly />
                </div>
              </div>

              <div
                className={`transition-all duration-500 ${showFilters ? 'max-h-screen opacity-100' : 'max-h-0 overflow-hidden opacity-0'}`}
              >
                <div className="mt-4 flex justify-between gap-2 border-slate-400">
                  <form className="grid grid-cols-2 gap-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
                    <SelectInputForm<ICompany>
                      name="companies"
                      placeholder="Selecionar Empresa"
                      closeMenuOnSelect={false}
                      options={companies}
                      getOptionLabel={item => (item as ICompany).nome}
                      getOptionValue={item => String((item as ICompany).external_id)}
                      queryKeyName="CompaniesSelect"
                      getOptions={getCompanies}
                      isLoading={isFetchingCompanySelect}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="cidade"
                      placeholder="Filtrar por cidade"
                      options={getFormattedLabelString(CompaniesFilters?.cidade)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="uf"
                      placeholder="Filtrar por Estado"
                      options={getFormattedLabelString(CompaniesFilters?.uf)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="grupo_de_empresas"
                      placeholder="Filtrar por grupo de empresa"
                      options={getFormattedLabelString(CompaniesFilters?.grupo_de_empresas)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="tags"
                      placeholder="Filtrar por tags"
                      options={getFormattedLabelString(CompaniesFilters?.tags)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="comercial"
                      placeholder="Filtrar por comercial"
                      options={getFormattedLabelString(CompaniesFilters?.comercial)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="contabil"
                      placeholder="Filtrar pelo contábil"
                      options={getFormattedLabelString(CompaniesFilters?.contabil)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="financeiro"
                      placeholder="Filtrar pelo financeiro"
                      options={getFormattedLabelString(CompaniesFilters?.financeiro)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="contas_a_pagar"
                      placeholder="Filtrar por contas à pagar"
                      options={getFormattedLabelString(CompaniesFilters?.contas_a_pagar)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="encerramento_anual"
                      placeholder="Filtrar por encerramento anual"
                      options={getFormattedLabelString(CompaniesFilters?.encerramento_anual)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="fiscal"
                      placeholder="Filtrar por fiscal"
                      options={getFormattedLabelString(CompaniesFilters?.fiscal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="imposto_de_renda"
                      placeholder="filtrar por imposto de renda"
                      options={getFormattedLabelString(CompaniesFilters?.imposto_de_renda)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="integracao_contabil"
                      placeholder="Filtrar por integração contabil"
                      options={getFormattedLabelString(CompaniesFilters?.integracao_contabil)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="integracao_fiscal"
                      placeholder="Filtrar por integração fiscal"
                      options={getFormattedLabelString(CompaniesFilters?.integracao_fiscal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="pessoal"
                      placeholder="Filtrar por pessoal"
                      options={getFormattedLabelString(CompaniesFilters?.pessoal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="relacionamento_com_cliente"
                      placeholder="Filtrar por relacionamento com cliente"
                      options={getFormattedLabelString(
                        CompaniesFilters?.relacionamento_com_cliente,
                      )}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="societario"
                      placeholder="Filtrar por societário"
                      options={getFormattedLabelString(CompaniesFilters?.societario)}
                      controlMb={0}
                    />
                  </form>
                </div>
              </div>
            </FormProvider>
          </div>
          {isFetchingCompanyList ? (
            <div className="flex flex-col items-center gap-4">
              <Spinner size="lg" color="primary" />
              <span className="text-xl font-semibold">Carregando...</span>
            </div>
          ) : companiesList?.length === 0 ? (
            <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
          ) : filters.get('search') && companiesList?.length === 0 && !isFetchingCompanyList ? (
            <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
          ) : (
            <div className="grid grid-flow-row grid-cols-1 items-center justify-between gap-2">
              {companiesList?.map(item => (
                <div
                  className={`flex gap-4 rounded-lg border p-4 shadow-lg lg:flex-col ${
                    item?.variacao_faturamento >= 0 && item?.variacao_faturamento <= 19
                      ? 'border-green-500'
                      : item?.variacao_faturamento >= 20 && item?.variacao_faturamento <= 50
                        ? 'border-yellow-500'
                        : item?.variacao_faturamento > 50
                          ? 'border-red-500'
                          : 'border-border' // Classe padrão se variacao_faturamento não se enquadrar nas condições
                  }`}
                >
                  <div className="grid grid-cols-1 gap-9 lg:grid-cols-2">
                    <div className="grid grid-cols-2 gap-2 lg:grid-cols-3">
                      <div className="flex flex-col gap-2 ">
                        <span className="text-base font-bold">ID</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">{item?.external_id}</Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 ">
                        <span className="text-base font-bold">CNPJ</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">{formatCNPJ(item?.cnpj)}</Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 ">
                        <span className="text-base font-bold">Razão social</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">{item?.razao_social}</Text>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-2 lg:grid-cols-4">
                      <div className="flex flex-col gap-2 ">
                        <span className="text-base font-bold">Variação de faturamento</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">
                              {parseInt(String(item?.variacao_faturamento ?? 0), 10)}%
                            </Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 ">
                        <span className="text-base font-bold">Faturamento atual</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">
                              {toCurrency(item?.faturamento_atual)}
                            </Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="text-base font-bold">Faturamento Anterior</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">
                              {toCurrency(item?.faturamento_anterior)}
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <Paginator pagination={pagination} />
            </div>
          )}
        </div>
      </FormBox>
    </div>
  );
}
