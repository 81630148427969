import React from 'react';
import { Select } from '@chakra-ui/react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { CaretLeft, CaretRight } from 'phosphor-react';
import { IPagination } from '../../types/shared';

interface PaginatorProps {
  pagination: IPagination;
}

export const PaginatorXML = ({ pagination }: PaginatorProps) => {
  const [filters, setFilters] = useSearchParams();

  const updateFilters = (newParams: Record<string, string>) => {
    const currentParams = Object.fromEntries(filters.entries());
    setFilters(createSearchParams({ ...currentParams, ...newParams }));
  };

  return (
    <div className="flex justify-end space-x-2 pt-4">
      <div className="self-center">Linhas</div>

      <Select
        w={70}
        h="32px"
        value={filters.get('size') ?? '10'} // Usa '10' como valor padrão se 'size' for undefined
        onChange={e => {
          updateFilters({ page: '1', size: e.target.value });
        }}
      >
        {[10, 20, 30, 40, 50].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </Select>

      <button
        type="button"
        aria-label="anterior"
        className="flex items-center justify-center rounded-md border border-border bg-body px-1 hover:opacity-80 disabled:opacity-50"
        onClick={
          pagination.previous_page !== null
            ? () => {
                const currentPage = filters.get('page') ?? '1'; // Usar '1' como valor padrão se for nulo
                updateFilters({
                  page: (parseInt(currentPage, 10) - 1).toString(), // Incrementa o valor de página
                });
              }
            : undefined
        }
        disabled={pagination.previous_page === null}
      >
        <CaretLeft size={16} weight="bold" />
      </button>

      <button
        type="button"
        aria-label="proxima"
        className="flex items-center justify-center rounded-md border border-border bg-body px-1 hover:opacity-80 disabled:opacity-50"
        onClick={
          pagination.next_page !== null
            ? () => {
                const currentPage = filters.get('page') ?? '1'; // Usar '1' como valor padrão se for nulo
                updateFilters({
                  page: (parseInt(currentPage, 10) + 1).toString(), // Incrementa o valor de página
                });
              }
            : undefined
        }
        disabled={pagination.next_page === null}
      >
        <CaretRight size={16} weight="bold" />
      </button>
    </div>
  );
};
