/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Box, Button, Flex, Input, useBreakpointValue, Text } from '@chakra-ui/react';
import moment from 'moment';
import { CalendarPlus, CaretLeft, CaretRight } from 'phosphor-react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('pt-BR', ptBR);

const initialDates = {
  date_start: moment().subtract(6, 'days').toDate(),
  date_end: moment().toDate(),
};

export const DateToolbarFilter = ({ setFilters, filters = {}, showMonthYearOnly }: any) => {
  const [dateSelected, setDateSelected] = useState<any>(1);
  const [isDateOpened, setIsDateOpened] = useState<any>(false);
  const [dates, setDates] = useState<any>(initialDates);
  const [datesShow, setDatesShow] = useState<any>(initialDates);
  const size = useBreakpointValue({ base: 16, md: 20, lg: 24 });
  const arrayOP = showMonthYearOnly
    ? ['Mês anterior', 'Mês atual']
    : ['Mês anterior', 'Mês atual', '15 dias', '7 dias'];

  return (
    <Box>
      <div className="flex gap-2 ">
        {showMonthYearOnly ? (
          <Text className="flex gap-1 pb-4">
            <Text className="font-bold">Mês inicial: </Text>
            {moment(datesShow.date_end).format('MM/YYYY')}
          </Text>
        ) : (
          <>
            <Text className="flex gap-1 pb-4">
              <Text className="font-bold">Mês inicial: </Text>
              {moment(datesShow.date_start).format('MM/YYYY')}
            </Text>
            <Text className="flex gap-1 pb-4">
              <Text className="font-bold">Mês final: </Text>
              {moment(datesShow.date_end).format('MM/YYYY')}
            </Text>
          </>
        )}
      </div>

      <div
        className={
          showMonthYearOnly
            ? 'grid w-full grid-flow-row grid-cols-3 gap-2 md:grid-cols-3 xl:grid-cols-3'
            : 'grid w-full grid-flow-row grid-cols-3 gap-2 md:grid-cols-5 xl:grid-cols-5'
        }
      >
        {arrayOP.map((label, index) => (
          <Button
            key={index}
            variant="outline"
            colorScheme="none"
            borderBottom={dateSelected === label ? '5px solid' : '1px solid'}
            borderBottomColor={dateSelected === label ? 'theme.light.tertiary' : ''}
            mr={{ base: '10px', md: '20px' }}
            onClick={() => {
              const start =
                index === 0
                  ? moment().subtract(1, 'months').startOf('month')
                  : index === 1
                    ? moment().startOf('month')
                    : index === 2
                      ? moment().subtract(14, 'days')
                      : moment().subtract(6, 'days');
              const dateEnd = showMonthYearOnly
                ? moment(start).endOf('month').toDate()
                : moment().format('YYYY-MM-DD');

              // Atualiza os filtros sem `createSearchParams`
              setFilters({
                ...filters,
                date_start: moment(start).format('YYYY-MM-DD'),
                date_end: moment(dateEnd).format('YYYY-MM-DD'),
              });

              setDatesShow({
                date_start: moment(start).format('YYYY-MM-DD'),
                date_end: showMonthYearOnly
                  ? moment(dateEnd).endOf('month').toDate()
                  : moment().format('YYYY-MM-DD'),
              });
              setDateSelected(label);
            }}
            _hover={{
              opacity: 0.6,
            }}
            fontSize={{ base: '12px', md: '14px', lg: '16px' }}
            px={{ base: '8px', md: '12px', lg: '16px' }}
          >
            {label}
          </Button>
        ))}

        <Button
          variant="outline"
          colorScheme="none"
          borderBottom={dateSelected === 'dates' ? '5px solid' : '1px solid'}
          borderBottomColor={dateSelected === 'dates' ? 'theme.light.tertiary' : ''}
          mr={{ base: '10px', md: '20px' }}
          onClick={() => {
            setDateSelected('dates');
            setIsDateOpened(!isDateOpened);
          }}
          _hover={{
            opacity: 0.6,
          }}
        >
          <CalendarPlus size={size} />
        </Button>
      </div>

      {isDateOpened && (
        <Flex
          justifyContent={{ base: 'flex-end', md: 'end' }}
          mt="15px"
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'flex-end', md: 'center' }}
        >
          <Flex
            maxWidth={{ base: '100%', md: '400px' }}
            justifyContent="space-evenly"
            alignItems="center"
            mb={{ base: '10px', md: '0' }}
          >
            <CaretLeft size={20} />
            {showMonthYearOnly ? (
              <Box maxWidth="50%">
                <DatePicker
                  selected={dates.date_start}
                  onChange={date => {
                    const firstDayOfMonth = moment(date).startOf('month').toDate();
                    const lastDayOfMonth = moment(date).endOf('month').toDate();
                    const firstDayOfPreviousMonthShow = moment(date)
                      .subtract(1, 'months')
                      .startOf('month')
                      .toDate();

                    const lastDayOfCurrentMonthShow = moment(date).endOf('month').toDate();
                    setDates(old => ({
                      ...old,
                      date_start: firstDayOfMonth,
                      date_end: lastDayOfMonth,
                    }));

                    setDatesShow(old => ({
                      ...old,
                      date_start: firstDayOfPreviousMonthShow,
                      date_end: lastDayOfCurrentMonthShow,
                    }));
                  }}
                  selectsStart
                  locale="pt-BR"
                  dateFormat="MM yyyy"
                  startDate={dates.date_start}
                  endDate={dates.date_end}
                  customInput={<Input variant="flushed" w="100%" textAlign="center" />}
                  showMonthYearPicker
                />
              </Box>
            ) : (
              <>
                <Box maxWidth="25%">
                  <DatePicker
                    selected={dates.date_start}
                    onChange={date => {
                      setDates(old => ({ ...old, date_start: date }));
                      setDatesShow(old => ({ ...old, date_start: date }));
                    }}
                    selectsStart
                    locale="pt-BR"
                    dateFormat="dd MMM yyyy"
                    startDate={dates.date_start}
                    endDate={dates.date_end}
                    customInput={<Input variant="flushed" w="100%" textAlign="center" />}
                  />
                </Box>
                <Box maxWidth="25%">
                  <DatePicker
                    selected={dates.date_end}
                    onChange={date => {
                      setDates(old => ({ ...old, date_end: date }));
                      setDatesShow(old => ({ ...old, date_end: date }));
                    }}
                    selectsEnd
                    locale="pt-BR"
                    dateFormat="dd MMM yyyy"
                    startDate={dates.date_start}
                    endDate={dates.date_end}
                    customInput={<Input variant="flushed" w="100%" textAlign="center" />}
                  />
                </Box>
              </>
            )}
            <CaretRight size={20} />
          </Flex>
          <Button
            onClick={() => {
              setFilters({
                ...filters,
                date_start: moment(dates.date_start).format('YYYY-MM-DD'),
                date_end: moment(dates.date_end).format('YYYY-MM-DD'),
              });
              setIsDateOpened(false);
            }}
            variant="outline"
            colorScheme="none"
            color="text.secondary"
            _hover={{
              opacity: 0.6,
            }}
            fontSize={{ base: '12px', md: '14px', lg: '16px' }}
            px={{ base: '8px', md: '12px', lg: '16px' }}
            alignSelf={{ base: 'flex-end', md: 'auto' }}
          >
            Selecionar
          </Button>
        </Flex>
      )}
    </Box>
  );
};
