/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useAuth } from '../../../providers/auth';
import { InputForm } from '../../inputs/forms/InputForm';
import { FormButton } from '../../buttons/FormButton';

const schema = yup.object().shape({
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
  password: yup.string().required('Senha obrigatória'),
});

interface IForm {
  email: string;
  password: string;
}

export default function LoginForm() {
  const { Login } = useAuth();
  const navigate = useNavigate();
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const captchaRef = useRef<any>(null);

  const formMethods = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: process.env.NODE_ENV === 'development' ? 'sys_sensi@mailinator.com' : '',
      password: process.env.NODE_ENV === 'development' ? '123456' : '',
    },
  });

  const onSubmit = async (values: IForm) => {
    if (!captchaToken) {
      toast.error('Por favor, complete o captcha antes de enviar.', { position: 'top-center' });
      return;
    }

    try {
      await Login(values);
      toast.success('Login realizado com sucesso!', { position: 'top-center' });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.detail ?? 'Erro interno no sistema, Tente novamente mais tarde.',
        { position: 'top-center' },
      );
    } finally {
      setCaptchaToken(null);
      captchaRef.current?.resetCaptcha();
    }
  };

  const handleCaptchaVerification = (token: string) => {
    setCaptchaToken(token);
  };

  const handleCaptchaError = () => {
    toast.error('Erro ao verificar o captcha. Por favor, tente novamente.', {
      position: 'top-center',
    });
  };

  return (
    <div className="w-full">
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <FormProvider {...formMethods}>
          <InputForm name="email" label="E-mail" placeholder="Seu e-mail de login" />
          <InputForm name="password" type="password" label="Senha" placeholder="Senha" />

          <HCaptcha
            sitekey={process.env.REACT_APP_HCAPTCHA_SITEKEY ?? ''}
            onVerify={handleCaptchaVerification}
            onError={handleCaptchaError}
            ref={captchaRef}
          />

          <div className="flex justify-end">
            <Button variant="link" color="text.tertiary" onClick={() => navigate('/recover')}>
              Esqueci a senha?
            </Button>
          </div>

          <FormButton mt={4} isLoading={formMethods.formState.isSubmitting} type="submit" w="100%">
            Entrar
          </FormButton>
        </FormProvider>
      </form>
    </div>
  );
}
