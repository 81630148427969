import React from 'react';
import { Link } from 'react-router-dom';
import { IMenuIcon, PrivateRoutes } from '../../routes/private.routes';
import { NavItemMenu } from '../navItemMenu';
import { NavItem } from '../navItem';
import { useAuth } from '../../providers/auth';

const Options: React.FC<{ isOpen: boolean; handleClose?: () => void }> = ({
  isOpen,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  handleClose,
}) => {
  const { user } = useAuth();
  return (
    <div>
      {PrivateRoutes.filter(item => {
        if (user?.is_sympla) {
          return item.name === 'Sympla' && item.path === '/sympla';
        }
        return item.showSidebar && !item.isChildrenMenu && item.name !== 'Sympla';
      })
        .filter(item => {
          if (user?.grupo_id === 2 && item.path.startsWith('/configuracoes')) {
            return false;
          }
          return true;
        })
        .map(link => (
          <div key={link.name}>
            {link.isParentMenu ? (
              <NavItemMenu
                parentHref={link.path}
                icon={link.icon as IMenuIcon}
                openMenu={isOpen}
                parentName={link.name}
                childrenMenus={PrivateRoutes.filter(item => item.parentMenu === link.name)}
              />
            ) : (
              <Link to={link.path}>
                <NavItem href={link.path as string} icon={link.icon as IMenuIcon} openMenu={isOpen}>
                  {link.name}
                </NavItem>
              </Link>
            )}
          </div>
        ))}
    </div>
  );
};

export default Options;
