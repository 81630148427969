/* eslint-disable no-nested-ternary */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CurrencyDollarSimple, User } from 'phosphor-react';
import { RiFileList3Line } from 'react-icons/ri';
import { TbReportMoney } from 'react-icons/tb';
import { PageBox } from '../../../components/pageBox';
import { PageHeader } from '../../../components/PageHeader';
import { DashboardCard } from '../../../components/dashboardCard';

export function modulesHomeSupervisorPage() {
  const navigate = useNavigate();

  return (
    <PageBox>
      <PageHeader title="FISCAL" subtitle="Esses são os módulos disponíveis" />
      <div className="col-span-2 flex w-full flex-col gap-2 rounded-xl">
        <div className="grid grid-cols-1 gap-6 pb-14 md:grid-cols-2 lg:grid-cols-3 lg:pb-0 2xl:grid-cols-4">
          <DashboardCard
            title="ANÁLISE DE IMPOSTOS"
            value=""
            onClick={() => navigate('/modulesHome/supervisor/sub')}
          >
            <div className="flex items-center justify-center rounded-full bg-[#DFF3FF] p-3">
              <RiFileList3Line color="#008CCC" className="size-12" />
            </div>
          </DashboardCard>

          <DashboardCard
            title="ANÁLISE REGIME TRIBUTÁRIO EMPRESAS"
            value=""
            onClick={() => navigate('/modulesHome/supervisor/taxRegime')}
          >
            <div className="flex items-center justify-center rounded-full bg-[#FFE1C6] p-3">
              <TbReportMoney color="#F2994A" className="size-12" />
            </div>
          </DashboardCard>

          <DashboardCard
            title="GUIAS SIMPLES NACIONAL"
            value=""
            onClick={() => navigate('/modulesHome/supervisor/simples')}
          >
            <div className="flex items-center justify-center rounded-full bg-[#FFEB99] p-3">
              <CurrencyDollarSimple color="#4CAF50" className="size-12" />
            </div>
          </DashboardCard>
          <DashboardCard
            title="REGIME TRIBUTÁRIO SÓCIOS"
            value=""
            onClick={() => navigate('/modulesHome/supervisor/partner')}
          >
            <div className="flex items-center justify-center rounded-full bg-[#CFD8DC] p-3">
              <User color="#37474F" className="size-12" />
            </div>
          </DashboardCard>
        </div>
      </div>
    </PageBox>
  );
}
