import { QueryFunctionContext } from '@tanstack/react-query';
import { api } from '../services/api';
import { IDataNoPagination } from '../types/shared';

export interface IDashboard {
  data_emissao: string;
  nfe: number;
  nfce: number;
  nfse: number;
}
export interface ICompaniesMostFailures {
  id: number;
  nome: string;
  cnpj: number;
  razao_social: string;
  quebras: number;
  sem_quebras: number;
  total_nfe: number;
  total_nfce: number;
  total_nfse: number;
}
export interface ICompaniesHighestRevenue {
  total: number;
  id: number;
  nome: string;
  cnpj: number;
  razao_social: string;
}

export const useDashboard = () => {
  const getDashboard = async (
    key: QueryFunctionContext,
  ): Promise<IDataNoPagination<IDashboard>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IDataNoPagination<IDashboard>>('/api/v1/dashboard/', {
      params: filters,
    });
    return res.data;
  };
  const getDashboardCompaniesMostFailures = async (
    key: QueryFunctionContext,
  ): Promise<IDataNoPagination<ICompaniesMostFailures>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IDataNoPagination<ICompaniesMostFailures>>(
      '/api/v1/dashboard/top_quebras',
      {
        params: filters,
      },
    );
    return res.data;
  };
  const getDashboardCompaniesHighestRevenue = async (
    key: QueryFunctionContext,
  ): Promise<IDataNoPagination<ICompaniesHighestRevenue>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IDataNoPagination<ICompaniesHighestRevenue>>(
      '/api/v1/dashboard/top_faturamento',
      {
        params: filters,
      },
    );
    return res.data;
  };

  return {
    getDashboard,
    getDashboardCompaniesMostFailures,
    getDashboardCompaniesHighestRevenue,
  };
};
