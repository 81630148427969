/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { Button, Spinner, Text } from '@chakra-ui/react';
import { CiCircleCheck } from 'react-icons/ci';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { RiFileList3Line } from 'react-icons/ri';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { PageBox } from '../../../../components/pageBox';
import { ReturnButton } from '../../../../components/buttons/ReturnButton';
import { FormBox } from '../../../../components/formBox';
import { IData, IPagination, ISelect } from '../../../../types/shared';
import { ICompany, useCompanies } from '../../../../hooks/useCompanies';
import { Paginator } from '../../../../components/paginator';
import { DateToolbarFilter } from '../../../../components/DateToolbarFilter';
import { InputForm } from '../../../../components/inputs/forms/InputForm';
import SelectInputForm from '../../../../components/inputs/forms/SelectInputForm';
import { formatCNPJ, getFormattedLabelString } from '../../../../utils/FormatFunctions';

export function modulesHomeSupervisorSubPage() {
  const { getCompanies, getCompaniesFilters } = useCompanies();
  const [showFilters, setShowFilters] = useState(false);
  const toggleFilters = () => setShowFilters(prev => !prev);

  const [filters, setFilters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '10',
      screen_data: 'fiscal',
      search: '',
      date_start: moment().subtract(6, 'days').format('YYYY-MM-DD'), // Convertendo para string
      date_end: moment().format('YYYY-MM-DD'), // Convertendo para string
    }),
  );

  const filtersFormMethods = useForm<{ search: string }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.get('search') ?? '',
    },
  });

  const debounced = useDebounceCallback(values => {
    const fields = {
      search: values.search,
      id: values.companies?.external_id,
      cidade: values.cidade?.value,
      uf: values.uf?.value,
      grupo_de_empresas: values.grupo_de_empresas?.value,
      tags: values.tags?.value,
      comercial: values.comercial?.value,
      contabil: values.contabil?.value,
      financeiro: values.financeiro?.value,
      contas_a_pagar: values.contas_a_pagar?.value,
      encerramento_anual: values.encerramento_anual?.value,
      fiscal: values.fiscal?.value,
      imposto_de_renda: values.imposto_de_renda?.value,
      integracao_contabil: values.integracao_contabil?.value,
      integracao_fiscal: values.integracao_fiscal?.value,
      pessoal: values.pessoal?.value,
      relacionamento_com_cliente: values.relacionamento_com_cliente?.value,
      societario: values.societario?.value,
    };

    Object.entries(fields).forEach(([key, value]) => {
      if (value) filters.set(key, value);
      else filters.delete(key);
    });

    setFilters(filters);
  }, 500);

  useEffect(() => {
    const subscription = filtersFormMethods.watch(debounced);
    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch]);

  // const queryKeyQuebrasTotatais = ['getQuebrasTotais', { page: 1, size: 30, search: '' }];

  const queryKeyCompaniesFilters = ['CompaniesFilters'];
  const { data: CompaniesFiltersData } = useQuery({
    queryKey: queryKeyCompaniesFilters,
    queryFn: getCompaniesFilters,
  });

  const CompaniesFilters = CompaniesFiltersData;

  const { data: dataCompaniesSelect, isFetching: isFetchingCompanySelect } = useQuery<
    IData<ICompany[]>
  >({
    queryKey: ['CompaniesSelect', { page: 1, size: 30, search: '' }],
    queryFn: getCompanies,
  });
  const { items: companiesSelect } = dataCompaniesSelect?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  const { data: dataCompanies, isFetching: isFetchingCompany } = useQuery<IData<ICompany[]>>({
    queryKey: ['CompaniesSelect', Object.fromEntries([...Array.from(filters)])],
    queryFn: getCompanies,
  });

  const { items: companies, ...pagination } = dataCompanies?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  function formatarEmReais(valor) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor);
  }

  const formatImpostoCalculo = (item: ICompany, imposto: string) => {
    if (item.impostos_calculo[imposto] === 0 && item.impostos_calculo[imposto] === undefined) {
      return <CiCircleCheck color="#16DBCC" className="size-7" />;
    }
    if (
      item.impostos_calculo[imposto] === undefined &&
      item.impostos_calculo[imposto] === undefined
    ) {
      return <CiCircleCheck color="#16DBCC" className="size-7" />;
    }
    if (item.impostos_guia[imposto] !== undefined && item.impostos_calculo[imposto] === 0) {
      return <IoCloseCircleOutline color="#FF82AC" className="size-7" />;
    }
    if (
      (item.impostos_calculo[imposto] < 10 && item.impostos_calculo[imposto] === undefined) ||
      item.impostos_calculo[imposto] === 0
    ) {
      return <CiCircleCheck color="#16DBCC" className="size-7" />;
    }
    if (item.impostos_calculo[imposto] === item.impostos_guia[imposto]) {
      return (
        <>
          <CiCircleCheck color="#16DBCC" className="size-7" />
          {item.impostos_calculo[imposto] !== undefined && (
            <Text className="text-slate-500">
              {formatarEmReais(item?.impostos_calculo?.cofins)}
            </Text>
          )}
        </>
      );
    }

    if (item.impostos_calculo[imposto] !== undefined) {
      return <IoCloseCircleOutline color="#FF82AC" className="size-7" />;
    }
    return <IoCloseCircleOutline color="#FF82AC" className="size-7" />;
  };

  return (
    <PageBox>
      <div className="flex flex-col gap-2">
        <div className="flex w-1/3 content-center pb-5 ">
          <ReturnButton />

          <div className="h-full">
            <div className="flex items-center justify-center rounded-full bg-[#DFF3FF] p-3">
              <RiFileList3Line color="#008CCC" className="size-8" />
            </div>
          </div>
          <div className="flex w-full flex-col justify-center gap-1 pl-2">
            <Text fontWeight="bold" fontSize="16px">
              ANÁLISE DE IMPOSTOS
            </Text>
          </div>
        </div>
        <FormBox>
          <div className="flex flex-col justify-between gap-2">
            <FormProvider {...filtersFormMethods}>
              <div className="flex flex-col justify-between gap-4 lg:flex-row">
                <div className="flex content-center items-center justify-center gap-3 pt-10">
                  <InputForm
                    name="search"
                    placeholder="Buscar"
                    boxShadow="0px 2px 4px #1E293B0D"
                    controlMb={0}
                  />
                  <Button
                    onClick={toggleFilters}
                    rightIcon={
                      showFilters ? (
                        <MdKeyboardArrowUp size="25px" />
                      ) : (
                        <MdKeyboardArrowDown size="25px" />
                      )
                    }
                  >
                    {showFilters ? 'Esconder Filtros' : 'Mostrar Filtros'}
                  </Button>
                </div>

                <div className="justify-end ">
                  <DateToolbarFilter setFilters={setFilters} filters={filters} showMonthYearOnly />
                </div>
              </div>

              <div
                className={`transition-all duration-500 ${showFilters ? 'max-h-screen opacity-100' : 'max-h-0 overflow-hidden opacity-0'}`}
              >
                <div className="mt-4 flex justify-between gap-2 border-slate-400 shadow-sm">
                  <form className="grid grid-cols-2 gap-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
                    <SelectInputForm<ICompany>
                      name="companies"
                      placeholder="Selecionar Empresa"
                      closeMenuOnSelect={false}
                      options={companiesSelect}
                      getOptionLabel={item => (item as ICompany).nome}
                      getOptionValue={item => String((item as ICompany).external_id)}
                      queryKeyName="CompaniesSelect"
                      getOptions={getCompanies}
                      isLoading={isFetchingCompanySelect}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="cidade"
                      placeholder="Filtrar por cidade"
                      options={getFormattedLabelString(CompaniesFilters?.cidade)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="uf"
                      placeholder="Filtrar por Estado"
                      options={getFormattedLabelString(CompaniesFilters?.uf)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="grupo_de_empresas"
                      placeholder="Filtrar por grupo de empresa"
                      options={getFormattedLabelString(CompaniesFilters?.grupo_de_empresas)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="tags"
                      placeholder="Filtrar por tags"
                      options={getFormattedLabelString(CompaniesFilters?.tags)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="comercial"
                      placeholder="Filtrar por comercial"
                      options={getFormattedLabelString(CompaniesFilters?.comercial)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="contabil"
                      placeholder="Filtrar pelo contábil"
                      options={getFormattedLabelString(CompaniesFilters?.contabil)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="financeiro"
                      placeholder="Filtrar pelo financeiro"
                      options={getFormattedLabelString(CompaniesFilters?.financeiro)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="contas_a_pagar"
                      placeholder="Filtrar por contas à pagar"
                      options={getFormattedLabelString(CompaniesFilters?.contas_a_pagar)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="encerramento_anual"
                      placeholder="Filtrar por encerramento anual"
                      options={getFormattedLabelString(CompaniesFilters?.encerramento_anual)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="fiscal"
                      placeholder="Filtrar por fiscal"
                      options={getFormattedLabelString(CompaniesFilters?.fiscal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="imposto_de_renda"
                      placeholder="filtrar por imposto de renda"
                      options={getFormattedLabelString(CompaniesFilters?.imposto_de_renda)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="integracao_contabil"
                      placeholder="Filtrar por integração contabil"
                      options={getFormattedLabelString(CompaniesFilters?.integracao_contabil)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="integracao_fiscal"
                      placeholder="Filtrar por integração fiscal"
                      options={getFormattedLabelString(CompaniesFilters?.integracao_fiscal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="pessoal"
                      placeholder="Filtrar por pessoal"
                      options={getFormattedLabelString(CompaniesFilters?.pessoal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="relacionamento_com_cliente"
                      placeholder="Filtrar por relacionamento com cliente"
                      options={getFormattedLabelString(
                        CompaniesFilters?.relacionamento_com_cliente,
                      )}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="societario"
                      placeholder="Filtrar por societário"
                      options={getFormattedLabelString(CompaniesFilters?.societario)}
                      controlMb={0}
                    />
                  </form>
                </div>
              </div>
            </FormProvider>
          </div>
          <div className="flex flex-col gap-4  p-4">
            {isFetchingCompany ? (
              <div className="flex flex-col items-center gap-4">
                <Spinner size="lg" color="primary" />
                <span className="text-xl font-semibold">Carregando...</span>
              </div>
            ) : filters.get('search') && companies?.length === 0 && !isFetchingCompany ? (
              <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
            ) : (
              <div className="grid grid-flow-row grid-cols-1 items-center justify-between gap-2">
                {companies?.map(item => (
                  <div className="flex gap-4 rounded-lg border border-border bg-body p-4 shadow-lg lg:flex-col">
                    <div className="grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-4">
                      <div className="grid grid-cols-2 gap-2 lg:grid-cols-2">
                        <div className="flex flex-col gap-2 ">
                          <span className="text-base font-bold">ID</span>
                          <div>
                            <div className="flex items-center gap-2">
                              <Text className="text-slate-500">{item?.external_id}</Text>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col gap-2 ">
                          <span className="text-base font-bold">CNPJ</span>
                          <div>
                            <div className="flex items-center gap-2">
                              <Text className="text-slate-500">{formatCNPJ(item?.cnpj)}</Text>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-2 flex flex-col gap-2">
                          <span className="text-base font-bold">Razão social</span>
                          <div>
                            <div className="flex items-center gap-2">
                              <Text className="text-slate-500">{item?.razao_social}</Text>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-1 pt-2 md:grid-cols-4 lg:col-span-3 lg:grid-cols-5 lg:pt-0">
                        <div className="grid grid-cols-1 gap-4">
                          <div>
                            <span className="text-base font-bold">Guia COFINS</span>
                            <div>
                              {item?.impostos_guia?.cofins === null ? (
                                <Text className="text-slate-500">Guia não encontrada</Text>
                              ) : (
                                <div className="flex items-center gap-2">
                                  {item?.impostos_guia?.cofins === 0 &&
                                  item?.impostos_calculo?.cofins === 0 ? (
                                    <CiCircleCheck color="#16DBCC" className="size-7" />
                                  ) : item?.impostos_guia?.cofins ===
                                    item?.impostos_calculo?.cofins ? (
                                    <CiCircleCheck color="#16DBCC" className="size-7" />
                                  ) : (
                                    <IoCloseCircleOutline color="#FF82AC" className="size-7" />
                                  )}
                                  <Text className="text-slate-500">
                                    {formatarEmReais(item?.impostos_guia?.cofins)}
                                  </Text>
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <span className="text-base font-bold">Cálculo COFINS</span>
                            <div>
                              <div className="flex items-center gap-2">
                                {formatImpostoCalculo(item, 'confins')}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-1 gap-4">
                          <div>
                            <span className="text-base font-bold">Guia CSLL</span>
                            <div>
                              {item?.impostos_guia?.csll === null ? (
                                <Text className="text-slate-500">Guia não encontrada</Text>
                              ) : (
                                <div className="flex items-center gap-2">
                                  {item?.impostos_guia?.csll === 0 &&
                                  item?.impostos_calculo?.csll === 0 ? (
                                    <CiCircleCheck color="#16DBCC" className="size-7" />
                                  ) : item?.impostos_guia?.csll === item?.impostos_calculo?.csll ? (
                                    <CiCircleCheck color="#16DBCC" className="size-7" />
                                  ) : (
                                    <IoCloseCircleOutline color="#FF82AC" className="size-7" />
                                  )}
                                  <Text className="text-slate-500">
                                    {formatarEmReais(item?.impostos_guia?.csll)}
                                  </Text>
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <span className="text-base font-bold">Cálculo CSLL</span>
                            <div>{formatImpostoCalculo(item, 'csll')}</div>
                          </div>
                        </div>

                        <div className="grid grid-cols-1 gap-4">
                          <div>
                            <span className="text-base font-bold">Guia ICMS</span>
                            <div>
                              {item?.impostos_guia?.icms === null ? (
                                <Text className="text-slate-500">Guia não encontrada</Text>
                              ) : (
                                <div className="flex items-center gap-2">
                                  {item?.impostos_guia?.icms === 0 &&
                                  item?.impostos_calculo?.icms === 0 ? (
                                    <CiCircleCheck color="#16DBCC" className="size-7" />
                                  ) : item?.impostos_guia?.icms === item?.impostos_calculo?.icms ? (
                                    <CiCircleCheck color="#16DBCC" className="size-7" />
                                  ) : (
                                    <IoCloseCircleOutline color="#FF82AC" className="size-7" />
                                  )}
                                  <Text className="text-slate-500">
                                    {formatarEmReais(item?.impostos_guia?.icms)}
                                  </Text>
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <span className="text-base font-bold">Cálculo ICMS</span>
                            <div>{formatImpostoCalculo(item, 'icms')}</div>
                          </div>
                        </div>

                        <div className="grid grid-cols-1 gap-4">
                          <div>
                            <span className="text-base font-bold">Guia IRPJ</span>
                            <div>
                              {item?.impostos_guia?.irpj === null ? (
                                <Text className="text-slate-500">Guia não encontrada</Text>
                              ) : (
                                <div className="flex items-center gap-2">
                                  {item?.impostos_guia?.irpj === 0 &&
                                  item?.impostos_calculo?.irpj === 0 ? (
                                    <CiCircleCheck color="#16DBCC" className="size-7" />
                                  ) : item?.impostos_guia?.irpj === item?.impostos_calculo?.irpj ? (
                                    <CiCircleCheck color="#16DBCC" className="size-7" />
                                  ) : (
                                    <IoCloseCircleOutline color="#FF82AC" className="size-7" />
                                  )}
                                  <Text className="text-slate-500">
                                    {formatarEmReais(item?.impostos_guia?.irpj)}
                                  </Text>
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <span className="text-base font-bold">Cálculo IRPJ</span>
                            <div>{formatImpostoCalculo(item, 'irpj')}</div>
                          </div>
                        </div>

                        <div className="grid grid-cols-1 gap-4">
                          <div>
                            <span className="text-base font-bold">Guia PIS</span>
                            <div>
                              {item?.impostos_guia?.pis === null ? (
                                <Text className="text-slate-500">Guia não encontrada</Text>
                              ) : (
                                <div className="flex items-center gap-2">
                                  {item?.impostos_guia?.pis === 0 &&
                                  item?.impostos_calculo?.pis === 0 ? (
                                    <CiCircleCheck color="#16DBCC" className="size-7" />
                                  ) : item?.impostos_guia?.pis === item?.impostos_calculo?.pis ? (
                                    <CiCircleCheck color="#16DBCC" className="size-7" />
                                  ) : (
                                    <IoCloseCircleOutline color="#FF82AC" className="size-7" />
                                  )}
                                  <Text className="text-slate-500">
                                    {formatarEmReais(item?.impostos_guia?.pis)}
                                  </Text>
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <span className="text-base font-bold">Cálculo PIS</span>
                            <div>{formatImpostoCalculo(item, 'pis')}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <Paginator pagination={pagination} />
        </FormBox>
      </div>
    </PageBox>
  );
}
