/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Button, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useQuery } from '@tanstack/react-query';
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineInsertPageBreak } from 'react-icons/md';
import { createSearchParams, Link, useLocation, useSearchParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import { ReturnButton } from '../../../../components/buttons/ReturnButton';
import { InputForm } from '../../../../components/inputs/forms/InputForm';
import { FormBox } from '../../../../components/formBox';
import SelectInputForm from '../../../../components/inputs/forms/SelectInputForm';
import { IData, IPagination, ISelect } from '../../../../types/shared';
import { ICompany, useCompanies } from '../../../../hooks/useCompanies';
import { IClient } from '../../../../hooks/useClients';
import { formatCNPJ, getFormattedLabelString } from '../../../../utils/FormatFunctions';
import { DataTable } from '../../../../components/datatable';
import { ActionsButton } from '../../../../components/buttons/ActionsButton';
import { DateToolbarFilter } from '../../../../components/DateToolbarFilter';
import { PageBox } from '../../../../components/pageBox';

export function ModulesHomeIntegrationXmlAnalysisPage() {
  const { getCompanies, getCompaniesFilters } = useCompanies();
  const [showFilters, setShowFilters] = useState(false);
  const location = useLocation();

  // Obtém os parâmetros da query string
  const queryParams = new URLSearchParams(location.search);
  const searchParamStart = queryParams.get('date_start');
  const searchParamEnd = queryParams.get('date_end');

  const [filters, setFilters] = useSearchParams({
    page: '1',
    size: '10',
    search: '',
    companyId: '',
    date_start: moment().startOf('month').format('YYYY-MM-DD'), // Convertendo para string
    date_end: moment().endOf('month').format('YYYY-MM-DD'), // Convertendo para string
    screen_data: 'xml_analysis',
  });
  const toggleFilters = () => setShowFilters(prev => !prev);

  const columns: ColumnDef<ICompany>[] = [
    {
      accessorKey: 'external_id',
      header: 'ID',
    },
    {
      accessorKey: 'razao_social',
      header: 'Razão social',
    },
    {
      accessorKey: 'cnpj',
      header: 'CNPJ',
      cell: ({ row }) => {
        const value = row.original.cnpj;
        return formatCNPJ(value);
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        const company = row.original;
        const dateStart = filters.get('date_start');
        const dateEnd = filters.get('date_end');

        return (
          <ActionsButton aria-label="actions">
            <Link
              to={{
                pathname: `${company.id}`,
                search: createSearchParams({
                  date_start: dateStart || moment().subtract(6, 'days').format('YYYY-MM-DD'),
                  date_end: dateEnd || moment().format('YYYY-MM-DD'),
                }).toString(),
              }}
            >
              <Button variant="unstyled" p="6px" display="flex" alignItems="center" gap={2}>
                <Text>Ver notas </Text>
              </Button>
            </Link>
          </ActionsButton>
        );
      },
    },
  ];

  const filtersFormMethods = useForm<{
    search: string;
    companyId: any;
    cliente: IClient;
    cidade: ISelect<string>;
    uf: ISelect<string>;
    grupo_de_empresas: ISelect<string>;
    tags: ISelect<string>;
    comercial: ISelect<string>;
    contabil: ISelect<string>;
    financeiro: ISelect<string>;
    contas_a_pagar: ISelect<string>;
    encerramento_anual: ISelect<string>;
    fiscal: ISelect<string>;
    imposto_de_renda: ISelect<string>;
    integracao_contabil: ISelect<string>;
    integracao_fiscal: ISelect<string>;
    pessoal: ISelect<string>;
    relacionamento_com_cliente: ISelect<string>;
    societario: ISelect<string>;
  }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.get('search') ?? '',
    },
  });

  const debounced = useDebounceCallback(values => {
    const fields = {
      search: values.search,
      date_start: searchParamStart ?? filters.get('date_start') ?? '',
      date_end: searchParamEnd ?? filters.get('date_end') ?? '',
      id: values.companies?.external_id,
      cidade: values.cidade?.value,
      uf: values.uf?.value,
      grupo_de_empresas: values.grupo_de_empresas?.value,
      tags: values.tags?.value,
      comercial: values.comercial?.value,
      contabil: values.contabil?.value,
      financeiro: values.financeiro?.value,
      contas_a_pagar: values.contas_a_pagar?.value,
      encerramento_anual: values.encerramento_anual?.value,
      fiscal: values.fiscal?.value,
      imposto_de_renda: values.imposto_de_renda?.value,
      integracao_contabil: values.integracao_contabil?.value,
      integracao_fiscal: values.integracao_fiscal?.value,
      pessoal: values.pessoal?.value,
      relacionamento_com_cliente: values.relacionamento_com_cliente?.value,
      societario: values.societario?.value,
    };

    Object.entries(fields).forEach(([key, value]) => {
      if (value) filters.set(key, value);
      else filters.delete(key);
    });

    setFilters(filters);
  }, 500);

  useEffect(() => {
    const subscription = filtersFormMethods.watch(values => {
      debounced(values);
    });

    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch, searchParamStart, searchParamEnd]);

  const queryKeyCompaniesFilters = ['CompaniesFilters'];
  const { data: CompaniesFiltersData } = useQuery({
    queryKey: queryKeyCompaniesFilters,
    queryFn: getCompaniesFilters,
  });

  const CompaniesFilters = CompaniesFiltersData;

  const { data: dataCompaniesSelect, isFetching: isFetchingCompanySelect } = useQuery<
    IData<ICompany[]>
  >({
    queryKey: ['CompaniesSelect', { page: 1, size: 30, search: '' }],
    queryFn: getCompanies,
  });

  const { data: dataCompaniesList, isFetching: isFetchingCompanyList } = useQuery<
    IData<ICompany[]>
  >({
    queryKey: ['CompaniesList', Object.fromEntries([...Array.from(filters)])],
    queryFn: getCompanies,
  });

  const { items: companies } = dataCompaniesSelect?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  const { items: companiesList, ...pagination } = dataCompaniesList?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  return (
    <PageBox>
      <div className="flex content-start pb-5 md:w-1/3 lg:content-center ">
        <ReturnButton previousPage="modulesHome/integration" />

        <div className="h-full">
          <div className="flex items-center justify-center rounded-full bg-[#FFE1C6] p-3">
            <MdOutlineInsertPageBreak color="#F2994A" className="size-8" />
          </div>
        </div>
        <div className="flex w-full flex-col justify-center gap-1 pl-2">
          <Text fontWeight="bold" fontSize="16px">
            Análise do XML NFS-e
          </Text>
        </div>
      </div>
      <FormBox>
        <div className="flex flex-col gap-4  p-4">
          <div className="flex flex-col justify-between gap-2">
            <FormProvider {...filtersFormMethods}>
              <div className="flex flex-col justify-between gap-4 lg:flex-row">
                <div className="flex content-center items-center justify-center gap-3 pt-10">
                  <InputForm
                    name="search"
                    placeholder="Buscar por nome, cnpj, razão social"
                    boxShadow="0px 2px 4px #1E293B0D"
                    controlMb={0}
                  />
                  <Button
                    onClick={toggleFilters}
                    rightIcon={
                      showFilters ? (
                        <MdKeyboardArrowUp size="25px" />
                      ) : (
                        <MdKeyboardArrowDown size="25px" />
                      )
                    }
                  >
                    {showFilters ? 'Esconder Filtros' : 'Mostrar Filtros'}
                  </Button>
                </div>

                <div className="justify-end ">
                  <DateToolbarFilter setFilters={setFilters} filters={filters} showMonthYearOnly />
                </div>
              </div>

              <div
                className={`transition-all duration-500 ${showFilters ? 'max-h-screen opacity-100' : 'max-h-0 overflow-hidden opacity-0'}`}
              >
                <div className="mt-4 flex justify-between gap-2 border-slate-400 shadow-sm">
                  <form className="grid grid-cols-2 gap-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
                    <SelectInputForm<ICompany>
                      name="companies"
                      placeholder="Selecionar Empresa"
                      closeMenuOnSelect={false}
                      options={companies}
                      getOptionLabel={item => (item as ICompany).nome}
                      getOptionValue={item => String((item as ICompany).external_id)}
                      queryKeyName="CompaniesSelect"
                      getOptions={getCompanies}
                      isLoading={isFetchingCompanySelect}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="cidade"
                      placeholder="Filtrar por cidade"
                      options={getFormattedLabelString(CompaniesFilters?.cidade)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="uf"
                      placeholder="Filtrar por Estado"
                      options={getFormattedLabelString(CompaniesFilters?.uf)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="grupo_de_empresas"
                      placeholder="Filtrar por grupo de empresa"
                      options={getFormattedLabelString(CompaniesFilters?.grupo_de_empresas)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="tags"
                      placeholder="Filtrar por tags"
                      options={getFormattedLabelString(CompaniesFilters?.tags)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="comercial"
                      placeholder="Filtrar por comercial"
                      options={getFormattedLabelString(CompaniesFilters?.comercial)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="contabil"
                      placeholder="Filtrar pelo contábil"
                      options={getFormattedLabelString(CompaniesFilters?.contabil)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="financeiro"
                      placeholder="Filtrar pelo financeiro"
                      options={getFormattedLabelString(CompaniesFilters?.financeiro)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="contas_a_pagar"
                      placeholder="Filtrar por contas à pagar"
                      options={getFormattedLabelString(CompaniesFilters?.contas_a_pagar)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="encerramento_anual"
                      placeholder="Filtrar por encerramento anual"
                      options={getFormattedLabelString(CompaniesFilters?.encerramento_anual)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="fiscal"
                      placeholder="Filtrar por fiscal"
                      options={getFormattedLabelString(CompaniesFilters?.fiscal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="imposto_de_renda"
                      placeholder="filtrar por imposto de renda"
                      options={getFormattedLabelString(CompaniesFilters?.imposto_de_renda)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="integracao_contabil"
                      placeholder="Filtrar por integração contabil"
                      options={getFormattedLabelString(CompaniesFilters?.integracao_contabil)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="integracao_fiscal"
                      placeholder="Filtrar por integração fiscal"
                      options={getFormattedLabelString(CompaniesFilters?.integracao_fiscal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="pessoal"
                      placeholder="Filtrar por pessoal"
                      options={getFormattedLabelString(CompaniesFilters?.pessoal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="relacionamento_com_cliente"
                      placeholder="Filtrar por relacionamento com cliente"
                      options={getFormattedLabelString(
                        CompaniesFilters?.relacionamento_com_cliente,
                      )}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="societario"
                      placeholder="Filtrar por societário"
                      options={getFormattedLabelString(CompaniesFilters?.societario)}
                      controlMb={0}
                    />
                  </form>
                </div>
              </div>
            </FormProvider>
          </div>
          <DataTable<ICompany>
            columns={columns}
            data={companiesList}
            pagination={pagination}
            emptyMsg="Nenhuma empresa encontrada."
            loadingMsg="Carregando Empresas..."
            isFetching={isFetchingCompanyList}
            getRowClassName={row =>
              row.variacao_faturamento >= 0 && row.variacao_faturamento <= 19
                ? 'border border-2 border-green-500'
                : row.variacao_faturamento >= 20 && row.variacao_faturamento <= 50
                  ? 'border border-2 border-yellow-500'
                  : row.variacao_faturamento > 50
                    ? 'border border-2 border-red-500'
                    : 'border border-2 border-border'
            }
          />
        </div>
      </FormBox>
    </PageBox>
  );
}
