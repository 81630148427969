/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import _ from 'lodash';
import { Props, Select } from 'chakra-react-select';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { useDebounceCallback } from 'usehooks-ts';
import { IData, IPagination } from '../../../../types/shared';
import { colors } from '../../../../theme/colors';

interface SelectProps<TData> extends Omit<Props, 'onChange'> {
  name: string;
  label?: string;
  labelCN?: React.ComponentProps<typeof FormLabel>['className'];
  optionAll?: TData;
  controlWidth?: number;
  controlMb?: number;
  extraFilters?: object;
  onValueChange?(props: TData): void;
  variant?: 'outline' | 'filled' | 'flushed' | 'unstyled';
  queryKeyName?: string;
  queryEnabled?: boolean;
  getOptions?: QueryFunction<IData<TData[]>>;
  isRequired?: boolean;
}

function SelectInputForm<TData>({
  name,
  label,
  labelCN,
  placeholder,
  optionAll,
  controlWidth,
  controlMb,
  onValueChange,
  extraFilters,
  queryKeyName,
  queryEnabled = true,
  getOptions,
  isRequired,
  ...rest
}: SelectProps<TData>) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [optionsList, setOptionsList] = useState<TData[]>([]);
  const [filter, setFilter] = useState({
    page: 1,
    size: 30,
    search: '',
    ...extraFilters,
  });
  const pageRef = useRef(1);

  const {
    formState: { errors },
    control,
  } = useFormContext();

  const queryKey = [queryKeyName, { ...filter, ...extraFilters }];
  const { data, isFetching } = useQuery<IData<TData[]>>({
    enabled: !!getOptions && isMenuOpen && queryEnabled,
    queryKey,
    queryFn: getOptions,
  });
  const { items: options, ...pagination } = useMemo(
    () =>
      data?.data ?? {
        items: [],
        ...({} as IPagination),
      },
    [data?.data],
  );

  const error = _.get(errors, name) as any;

  const debounced = useDebounceCallback(
    search => setFilter(oldState => ({ ...oldState, search, page: 1 })),
    500,
  );

  const onMenuScrollToBottom = () => {
    const nextPage = pageRef.current + 1;
    if (pagination?.next_page) {
      setFilter(oldState => ({
        ...oldState,
        page: nextPage,
      }));

      pageRef.current = nextPage;
    }
  };

  useEffect(() => {
    if (filter.page === 1)
      optionAll ? setOptionsList([optionAll, ...options]) : setOptionsList(options);
    else setOptionsList(old => [...old, ...options]);
  }, [options]);

  return (
    <FormControl isRequired={isRequired} isInvalid={!!error} mb={controlMb ?? 5}>
      {label && (
        <FormLabel className={labelCN} fontWeight="bold" mb={1} mr={3} htmlFor={name}>
          {label}
        </FormLabel>
      )}

      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, ref } }) => {
          return (
            <Select
              options={optionsList}
              ref={ref}
              onChange={newOptions => {
                onChange(newOptions);
                onValueChange && onValueChange(newOptions as TData);
              }}
              isLoading={getOptions ? isFetching : false}
              isClearable
              isSearchable
              onInputChange={getOptions ? debounced : () => {}}
              value={value}
              placeholder={placeholder ?? 'Selecione uma opção'}
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
              loadingMessage={() => 'Carregando...'}
              onMenuClose={() => {
                pageRef.current = 1;
              }}
              onBlur={() => setIsMenuOpen(false)}
              onMenuOpen={() => setIsMenuOpen(true)}
              onMenuScrollToBottom={getOptions ? onMenuScrollToBottom : () => {}}
              chakraStyles={{
                control: (styles, { isDisabled }) => ({
                  ...styles,
                  w: controlWidth ?? '100%',
                  minH: '44px',
                  opacity: isDisabled ? 0.9 : 1,
                  backgroundColor: '#FFF',
                  border: error ? '2px solid red' : '',
                  borderColor: colors.border,
                }),
                menuList: provided => ({
                  ...provided,
                  zIndex: 9999,
                  bg: 'white',
                  maxH: '150px',
                  borderColor: colors.border,
                  borderRadius: 0,
                  boxShadow: '0px 2px 4px #1E293B0D',
                }),
                dropdownIndicator: provided => ({
                  ...provided,
                  bg: 'transparent',
                  p: 0,
                  w: 3,
                  mx: 2,
                  cursor: 'inherit',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
              }}
              {...rest}
            />
          );
        }}
      />

      {errors && <FormErrorMessage>{String(error?.message)}</FormErrorMessage>}
    </FormControl>
  );
}

export default SelectInputForm;
