/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Box, Button, Input } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { toast } from 'sonner';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { PageHeader } from '../../components/PageHeader';
import { IData, IDataNoPagination, IPagination, ISelect } from '../../types/shared';
import { DataTable } from '../../components/datatable';
import { TableBox } from '../../components/tableBox';
import { InputForm } from '../../components/inputs/forms/InputForm';

import SelectInputForm from '../../components/inputs/forms/SelectInputForm';
import { IClient, useClients } from '../../hooks/useClients';
import { ICompany, useCompanies } from '../../hooks/useCompanies';
import { formatCNPJ, getFormattedLabelString } from '../../utils/FormatFunctions';
import { useAuth } from '../../providers/auth';

export function CompaniesPage() {
  const { getCompanies, uploadCompanyFile, getCompaniesFilters } = useCompanies();
  const { getClient } = useClients();
  const [showFilters, setShowFilters] = useState(false);
  const { user } = useAuth();
  const toggleFilters = () => setShowFilters(prev => !prev);

  const [filters, setFilters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '10',
      search: '',
    }),
  );

  const { mutateAsync: mutateAsyncUpload } = useMutation({
    mutationFn: uploadCompanyFile,
  });

  const filtersFormMethods = useForm<{
    search: string;
    cliente: IClient;
    cidade: ISelect<string>;
    uf: ISelect<string>;
    grupo_de_empresas: ISelect<string>;
    tags: ISelect<string>;
    comercial: ISelect<string>;
    contabil: ISelect<string>;
    financeiro: ISelect<string>;
    contas_a_pagar: ISelect<string>;
    encerramento_anual: ISelect<string>;
    fiscal: ISelect<string>;
    imposto_de_renda: ISelect<string>;
    integracao_contabil: ISelect<string>;
    integracao_fiscal: ISelect<string>;
    pessoal: ISelect<string>;
    relacionamento_com_cliente: ISelect<string>;
    societario: ISelect<string>;
  }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.get('search') ?? '',
    },
  });

  const debounced = useDebounceCallback(
    values =>
      setFilters(oldState => ({
        ...oldState,
        search: values.search,
        cidade: values.cidade?.value ?? '',
        uf: values.uf?.value ?? '',
        grupo_de_empresas: values.grupo_de_empresas?.value ?? '',
        tags: values.tags?.value ?? '',
        comercial: values.comercial?.value ?? '',
        contabil: values.contabil?.value ?? '',
        financeiro: values.financeiro?.value ?? '',
        contas_a_pagar: values.contas_a_pagar?.value ?? '',
        encerramento_anual: values.encerramento_anual?.value ?? '',
        fiscal: values.fiscal?.value ?? '',
        imposto_de_renda: values.imposto_de_renda?.value ?? '',
        integracao_contabil: values.integracao_contabil?.value ?? '',
        integracao_fiscal: values.integracao_fiscal?.value ?? '',
        pessoal: values.pessoal?.value ?? '',
        relacionamento_com_cliente: values.relacionamento_com_cliente?.value ?? '',
        societario: values.societario?.value ?? '',
        page: 1,
      })),
    500,
  );

  const queryKey = ['ucs', Object.fromEntries([...Array.from(filters)])];
  const { data, isFetching } = useQuery<IData<ICompany[]>>({
    queryKey,
    queryFn: getCompanies,
  });
  const { items: ucs, ...pagination } = data?.data ?? { items: [], ...({} as IPagination) };

  const queryKeyClient = ['clientSelected', { clientId: filters.get('cliente_id') }];

  const { data: dataClient } = useQuery<IDataNoPagination<IClient>>({
    enabled: !!filters.get('cliente_id') && !filtersFormMethods.watch('cliente'),
    queryKey: queryKeyClient,
    queryFn: getClient,
  });
  const clientSelected = dataClient?.data;

  const queryKeyCompaniesFilters = ['CompaniesFilters'];
  const { data: CompaniesFiltersData } = useQuery({
    queryKey: queryKeyCompaniesFilters,
    queryFn: getCompaniesFilters,
  });

  const CompaniesFilters = CompaniesFiltersData;

  const columns: ColumnDef<ICompany>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
    },
    {
      accessorKey: 'razao_social',
      header: 'Razão social',
    },
    {
      accessorKey: 'cnpj',
      header: 'CNPJ',
      cell: ({ row }) => {
        const value = row.original.cnpj;
        return value ? formatCNPJ(value) : '';
      },
    },
    {
      accessorKey: 'grupo_de_empresas',
      header: 'Grupo de empresa',
    },
    {
      accessorKey: 'tags',
      header: 'Tags',
    },
    {
      accessorKey: 'comercial',
      header: 'Comercial',
    },
    {
      accessorKey: 'contabil',
      header: 'contábil',
    },
    {
      accessorKey: 'financeiro',
      header: 'Financeiro',
    },
    {
      accessorKey: 'contas_a_pagar',
      header: 'Contas à pagar',
    },
    {
      accessorKey: 'encerramento_anual',
      header: 'Encerramento anual',
    },
    {
      accessorKey: 'fiscal',
      header: 'Fiscal',
    },
    {
      accessorKey: 'imposto_de_renda',
      header: 'Imposto de renda',
    },
    {
      accessorKey: 'integracao_contabil',
      header: 'Integração contábil',
    },
  ];

  useEffect(() => {
    const subscription = filtersFormMethods.watch(debounced);
    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch]);

  useEffect(() => {
    if (clientSelected) filtersFormMethods.resetField('cliente', { defaultValue: clientSelected });
  }, [clientSelected]);

  const handleFileUpload = async event => {
    const file = event.target.files;
    if (file) {
      try {
        await mutateAsyncUpload({
          file,
        });
        toast.success(`Empresas importadas com sucesso`, {
          position: 'top-center',
          duration: 2000,
        });
      } catch (error: any) {
        toast.error(
          error.response?.data?.detail ?? 'Erro interno no sistema, tente novamente mais tarde.',
          { position: 'top-right', duration: 2000 },
        );
      }
    }
    event.target.value = null;
  };
  function downloadProjectFile(fileName: string) {
    const filePath = `/${fileName}`;

    const link = document.createElement('a');
    link.href = filePath;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  return (
    <Box>
      <PageHeader title="Empresas" />
      <div className="flex justify-end gap-3 py-4">
        <Button
          className="bg-red border-2"
          onClick={() => downloadProjectFile('S3D_empresas_20240814155232_60506.xlsx')}
        >
          baixar arquivo de exemplo
        </Button>
        {user.grupo_id !== 2 && (
          <Button as="label" htmlFor="file-upload">
            Importar Empresas
            <Input
              type="file"
              id="file-upload"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              display="none"
            />
          </Button>
        )}
      </div>

      <TableBox>
        <FormProvider {...filtersFormMethods}>
          <div className="flex w-fit flex-row gap-4">
            <InputForm
              name="search"
              placeholder="Buscar por nome, cnpj, razão social"
              boxShadow="0px 2px 4px #1E293B0D"
              controlMb={0}
            />
            <Button
              onClick={toggleFilters}
              rightIcon={
                showFilters ? (
                  <MdKeyboardArrowUp size="25px" />
                ) : (
                  <MdKeyboardArrowDown size="25px" />
                )
              }
            >
              {showFilters ? 'Esconder Filtros' : 'Mostrar Filtros'}
            </Button>
          </div>

          <div
            className={`transition-all duration-500 ${showFilters ? 'max-h-screen opacity-100' : 'max-h-0 overflow-hidden opacity-0'}`}
          >
            <div className="mt-4 flex justify-between gap-2 border-slate-400 shadow-sm">
              <form className="grid grid-cols-2 gap-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
                <SelectInputForm<ISelect<string>>
                  name="cidade"
                  placeholder="Filtrar por cidade"
                  options={getFormattedLabelString(CompaniesFilters?.cidade)}
                  controlMb={0}
                />
                <SelectInputForm<ISelect<string>>
                  name="uf"
                  placeholder="Filtrar por Estado"
                  options={getFormattedLabelString(CompaniesFilters?.uf)}
                  controlMb={0}
                />
                <SelectInputForm<ISelect<string>>
                  name="grupo_de_empresas"
                  placeholder="Filtrar por grupo de empresa"
                  options={getFormattedLabelString(CompaniesFilters?.grupo_de_empresas)}
                  controlMb={0}
                />
                <SelectInputForm<ISelect<string>>
                  name="tags"
                  placeholder="Filtrar por tags"
                  options={getFormattedLabelString(CompaniesFilters?.tags)}
                  controlMb={0}
                />
                <SelectInputForm<ISelect<string>>
                  name="comercial"
                  placeholder="Filtrar por comercial"
                  options={getFormattedLabelString(CompaniesFilters?.comercial)}
                  controlMb={0}
                />
                <SelectInputForm<ISelect<string>>
                  name="contabil"
                  placeholder="Filtrar pelo contábil"
                  options={getFormattedLabelString(CompaniesFilters?.contabil)}
                  controlMb={0}
                />
                <SelectInputForm<ISelect<string>>
                  name="financeiro"
                  placeholder="Filtrar pelo financeiro"
                  options={getFormattedLabelString(CompaniesFilters?.financeiro)}
                  controlMb={0}
                />
                <SelectInputForm<ISelect<string>>
                  name="contas_a_pagar"
                  placeholder="Filtrar por contas à pagar"
                  options={getFormattedLabelString(CompaniesFilters?.contas_a_pagar)}
                  controlMb={0}
                />
                <SelectInputForm<ISelect<string>>
                  name="encerramento_anual"
                  placeholder="Filtrar por encerramento anual"
                  options={getFormattedLabelString(CompaniesFilters?.encerramento_anual)}
                  controlMb={0}
                />
                <SelectInputForm<ISelect<string>>
                  name="fiscal"
                  placeholder="Filtrar por fiscal"
                  options={getFormattedLabelString(CompaniesFilters?.fiscal)}
                  controlMb={0}
                />
                <SelectInputForm<ISelect<string>>
                  name="imposto_de_renda"
                  placeholder="filtrar por imposto de renda"
                  options={getFormattedLabelString(CompaniesFilters?.imposto_de_renda)}
                  controlMb={0}
                />
                <SelectInputForm<ISelect<string>>
                  name="integracao_contabil"
                  placeholder="Filtrar por integração contabil"
                  options={getFormattedLabelString(CompaniesFilters?.integracao_contabil)}
                  controlMb={0}
                />
                <SelectInputForm<ISelect<string>>
                  name="integracao_fiscal"
                  placeholder="Filtrar por integração fiscal"
                  options={getFormattedLabelString(CompaniesFilters?.integracao_fiscal)}
                  controlMb={0}
                />
                <SelectInputForm<ISelect<string>>
                  name="pessoal"
                  placeholder="Filtrar por pessoal"
                  options={getFormattedLabelString(CompaniesFilters?.pessoal)}
                  controlMb={0}
                />
                <SelectInputForm<ISelect<string>>
                  name="relacionamento_com_cliente"
                  placeholder="Filtrar por relacionamento com cliente"
                  options={getFormattedLabelString(CompaniesFilters?.relacionamento_com_cliente)}
                  controlMb={0}
                />
                <SelectInputForm<ISelect<string>>
                  name="societario"
                  placeholder="Filtrar por societário"
                  options={getFormattedLabelString(CompaniesFilters?.societario)}
                  controlMb={0}
                />
              </form>
            </div>
          </div>
        </FormProvider>

        <div className="border border-b-divider" />

        <DataTable<ICompany>
          columns={columns}
          data={ucs}
          pagination={pagination}
          emptyMsg="Nenhuma empresa encontrada."
          loadingMsg="Carregando Empresas..."
          isFetching={isFetching}
        />
      </TableBox>
    </Box>
  );
}
