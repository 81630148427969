import { api } from '../services/api';

export const useSpreadsheet = () => {
  const getSpreadsheet = async (data: any): Promise<void> => {
    const res = await api.get<Blob>('/api/v1/empresas/sieg/export/', {
      params: data,
      responseType: 'blob', // Garante que a resposta seja um blob
    });

    // Cria uma URL para o Blob
    const blob = new Blob([res.data], { type: res.headers['content-type'] });
    const url = window.URL.createObjectURL(blob);

    // Cria o link para download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'analise_XML.xlsx'); // Define o nome do arquivo

    // Adiciona o link ao documento e simula o clique para download
    document.body.appendChild(link);
    link.click();

    // Remove o link do documento
    document.body.removeChild(link);

    // Libera a URL do Blob
    window.URL.revokeObjectURL(url);
  };

  return {
    getSpreadsheet,
  };
};
