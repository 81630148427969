/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Box, Flex, Text, Image, Icon, Avatar } from '@chakra-ui/react';
import { VscError } from 'react-icons/vsc';
import { AiOutlineAudio, AiOutlineCheckCircle } from 'react-icons/ai';
import { GoProject } from 'react-icons/go';
import { MdOndemandVideo } from 'react-icons/md';
import { CgFileDocument } from 'react-icons/cg';
import moment from 'moment';
import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { handleImgError } from './HandleFunctions';

export const getCellExclusive = v => {
  if (v === true) {
    return <AiOutlineCheckCircle color="green" fontSize={25} />;
  }
  return <VscError color="red" fontSize={25} />;
};

export const toCurrency = (amount: number) => {
  const formatted = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(amount);

  return formatted;
};

export const getCellStatus = v => {
  if (v === 1 || v === true) {
    return (
      <span
        style={{ color: 'white', padding: 4, background: 'green', borderRadius: 5, fontSize: 14 }}
      >
        Ativo
      </span>
    );
  }
  return (
    <span style={{ color: 'white', padding: 4, background: 'red', borderRadius: 5, fontSize: 14 }}>
      Inativo
    </span>
  );
};

export const getCellColor = (value: any) => {
  if (value)
    return (
      <Flex align="center">
        <Box bg={value} borderRadius="5px" w="20px" h="20px" mr="5px" />
        <Text>{value}</Text>
      </Flex>
    );
  return 'Não informado';
};

export const getCellImage = (value: any, useAvatar = false) => {
  return (
    <>
      {useAvatar ? (
        <Avatar size="lg" src={value?.medium ?? ''} boxShadow="0 2px 4px #00000050" />
      ) : (
        <Image
          w="50px"
          borderRadius="5px"
          onError={handleImgError}
          src={value?.medium ?? ''}
          boxShadow="0 2px 4px #00000050"
        />
      )}
    </>
  );
};

export const getFileTypeIcon = (value: any) => {
  if (value === 'video')
    return (
      <Flex align="center">
        <Icon as={MdOndemandVideo} mr="5px" fontSize="25px" />
        <Text>Vídeo</Text>
      </Flex>
    );
  if (value === 'audio')
    return (
      <Flex align="center">
        <Icon as={AiOutlineAudio} mr="5px" fontSize="25px" />
        <Text>Audio</Text>
      </Flex>
    );
  if (value === 'docs')
    return (
      <Flex align="center">
        <Icon as={CgFileDocument} mr="5px" fontSize="25px" />
        <Text>Documento</Text>
      </Flex>
    );
  return 'Não informado';
};

export const getStatusDot = (value: any) => {
  if (value.length > 0)
    return [
      ...(value?.map(item => (
        <Flex align="center">
          <Icon as={GoProject} mr="5px" color={item.status ? 'green' : 'red'} fontSize="15px" />
          <Text>{item.name}</Text>
        </Flex>
      )) ?? []),
    ];
  return 'Não informado';
};

export const getArrayItems = (value: any) => {
  return <Text>{value.map(item => item.name).join(', ')}</Text>;
};

export const getAttachmentType = type => {
  switch (type) {
    case 1:
      return 'IMAGEM';
    case 2:
      return 'WORD';
    case 3:
      return 'PDF';
    case 4:
      return 'EXCEL';
    case 5:
      return 'POWER POINT';
    case 6:
      return 'ÁUDIO';
    case 7:
      return 'VÍDEO';
    default:
      return 'DESCONHECIDO';
  }
};

export const formatDate = (date, format = 'DD/MM/YYYY') => {
  if (format === 'ISO') return moment(date).toISOString();
  return moment(date).format(format);
};
export const getHtmlToDraftBlocks = html => {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};

export const getFormattedLabel = (item, label: string | null = null) => {
  return {
    value: item.id,
    label: label ?? item.name ?? item.id,
  };
};

export const formatCNPJ = (cnpj: string | number): string => {
  const cnpjStr = cnpj.toString().padStart(14, '0');
  return cnpjStr.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
};

export const formatIntermunicipal = (value: number | null) => {
  switch (value) {
    case 0:
      return 'Não identificado';
    case 1:
      return 'Sim';
    case 2:
      return 'Não';
    default:
      return 'Não identificado';
  }
};
export const getFormattedLabelString = (item: string[] | undefined) => {
  if (!item) return [];

  return item.map(city => ({
    label: city,
    value: city,
  }));
};
