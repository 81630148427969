import React from 'react';
import { HStack, Text, Box } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
} from 'recharts';

import moment from 'moment';
import { PageBox } from '../../../../components/pageBox';

import { IDataNoPagination } from '../../../../types/shared';
import {
  ICompaniesHighestRevenue,
  ICompaniesMostFailures,
  IDashboard,
  useDashboard,
} from '../../../../hooks/useDashboard';

import { DateToolbarFilter } from '../../../../components/DateToolbarFilterNoSearchParams';
import { ReturnButton } from '../../../../components/buttons/ReturnButton';

const initialFilters = {
  date_start: moment().subtract(6, 'days').format('YYYY-MM-DD'),
  date_end: moment().format('YYYY-MM-DD'),
};

export default function DashboardPage() {
  const { getDashboard, getDashboardCompaniesMostFailures, getDashboardCompaniesHighestRevenue } =
    useDashboard();

  // Filtros para o dashboard principal
  const [filters, setFilters] = React.useState(initialFilters);

  // Filtros para as empresas com mais quebras
  const [filtersDashboardCompaniesMostFailures, setFiltersDashboardCompaniesMostFailures] =
    React.useState(initialFilters);
  // HighestRevenue
  // Filtros para as empresas com Receitas
  const [filtersDashboardCompaniesHighestRevenue, setFiltersDashboardCompaniesHighestRevenue] =
    React.useState(initialFilters);

  const queryKey = ['dashboard', filters];
  const queryKeyCompaniesMostFailures = [
    'queryKeyCompaniesMostFailures',
    filtersDashboardCompaniesMostFailures,
  ];
  const queryKeyCompaniesHighestRevenue = [
    'queryKeyCompaniesHighestRevenue',
    filtersDashboardCompaniesHighestRevenue,
  ];

  const { data } = useQuery<IDataNoPagination<IDashboard>>({
    queryFn: getDashboard,
    queryKey,
  });

  const { data: dashboardCompaniesMostFailure } = useQuery<
    IDataNoPagination<ICompaniesMostFailures>
  >({
    queryFn: getDashboardCompaniesMostFailures,
    queryKey: queryKeyCompaniesMostFailures,
  });

  const { data: dashboardCompaniesHighestRevenue } = useQuery<
    IDataNoPagination<ICompaniesHighestRevenue>
  >({
    queryFn: getDashboardCompaniesHighestRevenue,
    queryKey: queryKeyCompaniesHighestRevenue,
  });

  const dashboard = data?.data;

  const total = dashboard
    ? (dashboard.nfe ?? 0) + (dashboard.nfce ?? 0) + (dashboard.nfse ?? 0)
    : 0;

  const companiesData = Array.isArray(dashboardCompaniesMostFailure?.data)
    ? dashboardCompaniesMostFailure?.data
    : [];

  const companiesHighestRevenueData = Array.isArray(dashboardCompaniesHighestRevenue?.data)
    ? dashboardCompaniesHighestRevenue?.data.map(company => ({
        ...company,
        nome: company.razao_social, // Fazendo nome igual a razao_social
      }))
    : [];

  const getPieData = entry => {
    const PieData =
      total > 0
        ? [
            { name: 'NFE', value: entry.nfe },
            { name: 'NFCE', value: entry.nfce },
            { name: 'NFSE', value: entry.nfse },
          ]
        : [
            { name: 'NFE', value: 0 },
            { name: 'NFCE', value: 0 },
            { name: 'NFSE', value: 0 },
          ];

    if (PieData.every(item => item.value === 0)) {
      PieData[0].value = 0;
    }

    return PieData;
  };

  const formatTooltipValue = value => {
    const numericValue = parseFloat(((value / total) * 100).toFixed(2));
    return `${numericValue.toFixed(2)}%`;
  };
  const formatValue = value => {
    return value.toLocaleString(); // Formata o número com separador de milhar
  };
  const formatYAxisTick = value => {
    if (value >= 1e6) {
      return `${(value / 1e6).toFixed(0)} milhões`;
    }
    if (value >= 1e3) {
      return `${(value / 1e3).toFixed(0)} mil`;
    }
    return value;
  };

  return (
    <PageBox>
      <ReturnButton label="Voltar para página anterior" previousPage="modulesHome/integration" />

      {/* Filtros para o dashboard principal */}
      <HStack justify="flex-end" mt={8} mb={8}>
        <DateToolbarFilter setFilters={setFilters} filters={filters} />
      </HStack>

      <div className="flex flex-col gap-5">
        <div className="grid gap-5 lg:grid-cols-1">
          <div className="flex w-full flex-col gap-4">
            <Text fontSize="18px" fontWeight="bold">
              NOTAS
            </Text>

            {(dashboard && (dashboard?.nfce || 0) > 0) ||
            (dashboard?.nfe || 0) > 0 ||
            (dashboard?.nfse || 0) > 0 ? (
              <div className="flex flex-col items-center">
                <div className="flex w-full justify-end rounded-lg bg-white p-5 shadow-lg">
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={getPieData(dashboard)}
                        dataKey="value"
                        nameKey="name"
                        outerRadius={100}
                        label
                      >
                        <Cell name="NFE" fill="#8884d8" />
                        <Cell name="NFCE" fill="#82ca9d" />
                        <Cell name="NFSE" fill="#ffc658" />
                      </Pie>
                      <Tooltip formatter={formatTooltipValue} />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            ) : (
              <Box className="flex items-center justify-center">
                <Text fontSize="18px" fontWeight="bold" color="gray.500">
                  Não existe dados suficientes para a data selecionada
                </Text>
              </Box>
            )}
          </div>

          {/* Filtros para as empresas com mais quebras */}
          <HStack justify="flex-end" mt={8} mb={8}>
            <DateToolbarFilter
              setFilters={setFiltersDashboardCompaniesMostFailures}
              filters={filtersDashboardCompaniesMostFailures}
            />
          </HStack>

          <div className="flex w-full flex-col gap-4">
            <Text fontSize="18px" fontWeight="bold">
              Empresas com mais quebras
            </Text>

            {dashboardCompaniesHighestRevenue && companiesData?.length > 0 ? (
              <div className="flex flex-col items-center">
                <div className="flex w-full justify-end rounded-lg bg-white p-5 shadow-lg">
                  <div style={{ overflowX: 'auto', width: '100%' }}>
                    <ResponsiveContainer height={300}>
                      <BarChart
                        data={companiesData}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="razao_social"
                          tickFormatter={nome => {
                            const words = nome.split(' ');
                            return words.length > 1 ? `${words[words.length - 1]}...` : nome;
                          }}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="quebras" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            ) : (
              <Box className="flex items-center justify-center">
                <Text fontSize="18px" fontWeight="bold" color="gray.500">
                  Não existe dados suficientes para a data selecionada
                </Text>
              </Box>
            )}
          </div>
          <HStack justify="flex-end" mt={8} mb={8}>
            <DateToolbarFilter
              setFilters={setFiltersDashboardCompaniesHighestRevenue}
              filters={filtersDashboardCompaniesHighestRevenue}
            />
          </HStack>

          <div className="flex w-full flex-col gap-4">
            <Text fontSize="18px" fontWeight="bold">
              Empresas com mais faturamento
            </Text>

            {dashboardCompaniesHighestRevenue && companiesHighestRevenueData?.length > 0 ? (
              <div className="flex h-[90%] flex-col items-center">
                <div className="flex  w-full justify-end rounded-lg bg-white p-5 shadow-lg">
                  <div style={{ overflowX: 'auto', width: '100%' }}>
                    <ResponsiveContainer height={400}>
                      <BarChart
                        data={companiesHighestRevenueData}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="razao_social"
                          tickFormatter={nome => {
                            const words = nome.split(' ');
                            return words.length > 1 ? `${words[words.length - 1]}...` : nome;
                          }}
                        />
                        <YAxis tickFormatter={formatYAxisTick} />
                        <Tooltip formatter={value => [formatValue(value)]} />
                        <Legend />
                        <Bar dataKey="total" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            ) : (
              <Box className="flex items-center justify-center">
                <Text fontSize="18px" fontWeight="bold" color="gray.500">
                  Não existe dados suficientes para a data selecionada
                </Text>
              </Box>
            )}
          </div>
        </div>
      </div>
    </PageBox>
  );
}
