import React from 'react';
import { PiIntersectSquareDuotone } from 'react-icons/pi';
import { FiDivideSquare } from 'react-icons/fi';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { RiFileList3Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '../../components/PageHeader';
import { useAuth } from '../../providers/auth';
import { PageBox } from '../../components/pageBox';
import { DashboardCard } from '../../components/dashboardCard';

export default function HomePage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  return (
    <PageBox>
      <PageHeader
        title={`Olá, ${user?.nome?.split(' ')?.[0]}`}
        subtitle="Esses são os módulos disponíveis para sua conta"
      />

      <div className="col-span-2 flex w-full flex-col gap-2 rounded-xl">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          <DashboardCard
            title="INTEGRAÇÃO"
            value=""
            onClick={() => navigate('/modulesHome/integration')}
          >
            <div className="flex items-center justify-center rounded-full bg-[#7AD8FF] p-3">
              <PiIntersectSquareDuotone color="#0A4E70" className="size-12" />
            </div>
          </DashboardCard>

          <DashboardCard
            title="FISCAL"
            value=""
            onClick={() => navigate('/modulesHome/supervisor')}
          >
            <div className="flex items-center justify-center rounded-full bg-[#DFF3FF] p-3">
              <RiFileList3Line color="#008CCC" className="size-12" />
            </div>
          </DashboardCard>

          <DashboardCard
            title="CONCILIAÇÃO CONTÁBIL"
            value=""
            onClick={() => navigate('/modulesHome/accountingConciliation')}
          >
            <div className="flex items-center justify-center rounded-full bg-[#FFE1C6] p-3">
              <FiDivideSquare color="#F2994A" className="size-12" />
            </div>
          </DashboardCard>

          <DashboardCard
            title="FOLHA DE PAGAMENTO"
            value=""
            onClick={() => navigate('/modulesHome/payRoll')}
          >
            <div className="flex items-center justify-center rounded-full bg-[#ABEDC6] p-3">
              <IoDocumentTextOutline color="#219653" className="size-12" />
            </div>
          </DashboardCard>
          {/* <DashboardCard
            title="PLANEJAMENTO TRIBUTÁRIO"
            value=""
            onClick={() => navigate('/modulesDashboard/taxPlanning')}
          >
            <div className="flex items-center justify-center rounded-full bg-[#C5B2D4] p-3">
              <IoCalculatorOutline color="#47305A" className="size-12" />
            </div>
          </DashboardCard> */}
        </div>
      </div>
    </PageBox>
  );
}
