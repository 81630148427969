/* eslint-disable no-unused-vars */
import React from 'react';
import { Box, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { IPagination } from '../../types/shared';
import { Paginator } from '../paginator';
import { PaginatorXML } from '../paginatorXML';

export interface DataTableProps<TData> {
  columns: ColumnDef<TData>[];
  data: TData[];
  pagination: IPagination;
  emptyMsg?: string;
  loadingMsg?: string;
  isFetching?: boolean;
  xmlPagination?: boolean;
  getRowClassName?: (row: TData) => string; // Nova prop opcional
}

export function DataTable<TData>({
  columns,
  data,
  pagination,
  emptyMsg,
  loadingMsg,
  isFetching,
  xmlPagination,
  getRowClassName, // Recebe a função como prop
}: DataTableProps<TData>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Box>
      <TableContainer
        whiteSpace="break-spaces"
        background="body"
        boxShadow="0px 1px 2px #0000000D"
        borderRadius="10px"
        borderColor="border"
        borderWidth="1px"
      >
        <Table variant="simple">
          <Thead>
            {table.getHeaderGroups().map(headerGroup => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <Th key={header.id} className="px-0 text-center font-bold text-primary">
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          <Tbody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <Tr
                  key={row.id}
                  className={getRowClassName ? getRowClassName(row.original) : ''}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map(cell => (
                    <Td key={cell.id} className="px-1 py-1 text-center">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  ))}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={columns.length} className="h-24 text-center text-lg">
                  {isFetching ? (
                    <div className="flex flex-col items-center gap-4">
                      <Spinner size="lg" color="primary" />
                      <span className="text-xl font-semibold">{loadingMsg ?? 'Carregando...'}</span>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center gap-4">
                      {emptyMsg ?? 'Nenhum resultado.'}
                    </div>
                  )}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {xmlPagination && <PaginatorXML pagination={pagination} />}
      {pagination.total > 10 && !xmlPagination && <Paginator pagination={pagination} />}
    </Box>
  );
}
