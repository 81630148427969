import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useQuery } from '@tanstack/react-query';
import { createSearchParams, useParams, useSearchParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { HStack } from '@chakra-ui/react';
import { ReturnButton } from '../../../../../components/buttons/ReturnButton';
import { IData, IPagination } from '../../../../../types/shared';
import { ISaida, useCompanies } from '../../../../../hooks/useCompanies';
import { DataTable } from '../../../../../components/datatable';
import { FormBox } from '../../../../../components/formBox';
import SelectInputForm from '../../../../../components/inputs/forms/SelectInputForm';
import { DateToolbarFilter } from '../../../../../components/DateToolbarFilter';

export function ModulesHomeIntegrationApiXSiegNotasPage() {
  const { getCompaniesSaidas } = useCompanies();
  const { itemId } = useParams();

  const columns: ColumnDef<ISaida>[] = [
    {
      accessorKey: 'nume_sai',
      header: 'Número de saída',
    },
    {
      accessorKey: 'seri_sai',
      header: 'Série de saida',
    },
    {
      accessorKey: 'sigl_est',
      header: 'Estado',
    },
    {
      accessorKey: 'verificado_sieg',
      header: 'Está na sieg?',
      cell: ({ row }) => {
        const value = row.original.verificado_sieg;
        return value ? 'Está na sieg' : 'Não está na sieg';
      },
    },
  ];

  const [filters, setFilters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '10',
      search: '',
      empresa_id: itemId || '',
    }),
  );

  const filtersFormMethods = useForm<{
    empresa_id: string;
    page: string;
    intermunicipal?: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      intermunicipal: filters.get('intermunicipal') ?? undefined,
    },
  });

  const debounced = useDebounceCallback(values => {
    const currentFilters = Object.fromEntries([...Array.from(filters)]);

    if (values?.intermunicipal?.value) {
      setFilters(
        createSearchParams({
          ...currentFilters,
          intermunicipal: values?.intermunicipal?.value || '',
          empresa_id: itemId || '',
          page: '1',
        }),
      );
    } else {
      setFilters(
        createSearchParams({
          ...currentFilters,
          empresa_id: itemId || '',
          page: '1',
        }),
      );
    }
  }, 200);

  useEffect(() => {
    const subscription = filtersFormMethods.watch(debounced);
    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch]);

  const { data: dataCompaniesList, isFetching: isFetchingCompanyList } = useQuery<IData<ISaida[]>>({
    queryKey: ['CompaniesList', Object.fromEntries([...Array.from(filters)])],
    queryFn: getCompaniesSaidas,
  });

  const { items: companiesList, ...pagination } = dataCompaniesList?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  return (
    <div className="flex w-full flex-col gap-10">
      <ReturnButton
        label="Voltar para página anterior"
        previousPage="modulesHome/integration/apixsieg"
      />
      <HStack justify="flex-end" mt={8}>
        <DateToolbarFilter filters={filters} setFilters={setFilters} />
      </HStack>

      <FormBox>
        <div className="flex flex-col gap-4  p-4">
          <div className="flex flex-col justify-between gap-2">
            <FormProvider {...filtersFormMethods}>
              <SelectInputForm<string>
                name="intermunicipal"
                options={[
                  { label: 'Municipais', value: 1 },
                  { label: 'Outros Municípios', value: 2 },
                  { label: 'Não identificado', value: 0 },
                ]}
              />
            </FormProvider>
          </div>
          <DataTable<ISaida>
            columns={columns}
            data={companiesList}
            pagination={pagination}
            emptyMsg="Nenhuma Nota encontrada."
            loadingMsg="Carregando Notas..."
            isFetching={isFetchingCompanyList}
            getRowClassName={row =>
              row.verificado_sieg
                ? 'border border-2 border-green-500'
                : 'border border-2 border-red-500'
            }
          />
        </div>
      </FormBox>
    </div>
  );
}
