import { GearSix, House, IconProps, ChartBar, Buildings, CalendarBlank } from 'phosphor-react';
import { ForwardRefExoticComponent, RefAttributes } from 'react';
import HomePage from '../pages/home';
import DashboardPage from '../pages/modulesHome/Integration/dashboard';
import { UsersPage } from '../pages/users';
import UsersCreatePage from '../pages/users/create';
import UsersEditPage from '../pages/users/edit';
// import { ClientsPage } from '../pages/clients';
import { GroupsPage } from '../pages/groups';
import SecurityPage from '../pages/security';
import ProfilePage from '../pages/profile';
import CompanyPage from '../pages/company';
import SessionsPage from '../pages/sessions';
// import ClientsCreatePage from '../pages/clients/create';
import GroupsCreatePage from '../pages/groups/create';
// import ClientsEditPage from '../pages/clients/edit';
import { PermissionsPage } from '../pages/permissions';
import PermissionsCreatePage from '../pages/permissions/create';
import PermissionsEditPage from '../pages/permissions/edit';
import modulesHomeIntegrationPage from '../pages/modulesHome/Integration';
import { modulesHomeAccountingConciliationPage } from '../pages/modulesHome/accountingConciliation';
import { modulesHomePayRollPage } from '../pages/modulesHome/payRoll';
import { modulesHomeSupervisorPage } from '../pages/modulesHome/Supervisor';
import { modulesHomeTaxPlanningPage } from '../pages/modulesHome/taxPlanning';
import GroupsEditPage from '../pages/groups/edit';
import { CompaniesPage } from '../pages/companies';
import { BreaksPage } from '../pages/breaks';
import { ModulesHomeIntegrationSequenceBreakPage } from '../pages/modulesHome/Integration/sequenceBreak';
import { ModulesHomeIntegrationBillingAnalysisPage } from '../pages/modulesHome/Integration/billingAnalysis';
import { ModulesHomeIntegrationXmlAnalysisPage } from '../pages/modulesHome/Integration/xmlAnalysis';
import { ModulesHomeIntegrationXmlAnalysisSHowNotasPage } from '../pages/modulesHome/Integration/xmlAnalysis/showNotas';
import { ModulesHomeIntegrationApiXSiegPage } from '../pages/modulesHome/Integration/apiXSieg';
import { ModulesHomeIntegrationApiXSiegNotasPage } from '../pages/modulesHome/Integration/apiXSieg/showNotas';
import { SymplaPage } from '../pages/sympla';
import { ViewSymplaPage } from '../pages/sympla/view';
import { ModulesHomeIntegrationDailyImportsPage } from '../pages/modulesHome/Integration/dailyImports';
import { ModulesHomeIntegrationManualPostingsPage } from '../pages/modulesHome/Integration/manualPostings';
import { modulesHomeSimplesPage } from '../pages/modulesHome/Simples';
import { modulesHomeSupervisorSubPage } from '../pages/modulesHome/Supervisor/supervisoSub';
import { ModulesHomeSupervisorTaxRegimePage } from '../pages/modulesHome/Supervisor/taxRegime';
import { ModulesHomeSupervisorPartinerPage } from '../pages/modulesHome/Supervisor/partner';
import { ModulesHomeSupervisorPartnerCompaniesPage } from '../pages/modulesHome/Supervisor/partner/viewCompanies';

export type IMenuIcon = ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>;
export interface IPrivateRoutes {
  name: string;
  path: string;
  component?: () => JSX.Element | undefined;
  componentCreate?: () => JSX.Element | undefined;
  componentEdit?: () => JSX.Element | undefined;
  componentShow?: () => JSX.Element | undefined;
  isParentMenu?: boolean;
  isChildrenMenu?: boolean;
  parentMenu?: string;
  showSidebar: boolean;
  icon?: IMenuIcon;
  permissionSympla?: boolean;
}

export const PrivateRoutes: IPrivateRoutes[] = [
  {
    name: 'Home',
    path: '/',
    component: HomePage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: true,
    icon: House,
  },
  {
    name: 'Dashboard integration',
    path: '/modulesHome/integration/dashboard',
    component: DashboardPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: false,
    icon: ChartBar,
  },
  {
    name: 'integration sequenceBreak',
    path: '/modulesHome/integration/sequenceBreak',
    component: ModulesHomeIntegrationSequenceBreakPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: false,
    icon: ChartBar,
  },
  {
    name: 'integration billingAnalysis',
    path: '/modulesHome/integration/billingAnalysis',
    component: ModulesHomeIntegrationBillingAnalysisPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: false,
    icon: ChartBar,
  },
  {
    name: 'integration xmlAnalysis',
    path: '/modulesHome/integration/xmlAnalysis',
    component: ModulesHomeIntegrationXmlAnalysisPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: ModulesHomeIntegrationXmlAnalysisSHowNotasPage,
    showSidebar: false,
    icon: ChartBar,
  },
  {
    name: 'integration apixsieg',
    path: '/modulesHome/integration/apixsieg',
    component: ModulesHomeIntegrationApiXSiegPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: ModulesHomeIntegrationApiXSiegNotasPage,
    showSidebar: false,
    icon: ChartBar,
  },
  {
    name: 'integration dailyImports',
    path: '/modulesHome/integration/dailyImports',
    component: ModulesHomeIntegrationDailyImportsPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: false,
    icon: ChartBar,
  },
  {
    name: 'integration manualPostings',
    path: '/modulesHome/integration/manualPostings',
    component: ModulesHomeIntegrationManualPostingsPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: false,
    icon: ChartBar,
  },
  {
    name: 'Quebras',
    path: '/breaks',
    component: undefined,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: BreaksPage,
    showSidebar: false,
    icon: ChartBar,
  },
  {
    name: 'Empresas',
    path: '/companies',
    component: CompaniesPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: true,
    icon: Buildings,
  },
  {
    name: 'Sympla',
    path: '/sympla',
    component: SymplaPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: ViewSymplaPage,
    showSidebar: true,
    icon: CalendarBlank,
  },
  {
    name: 'Modulos do dashboard integration',
    path: '/modulesHome/integration',
    component: modulesHomeIntegrationPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: false,
    icon: House,
  },
  {
    name: 'Modulos do dashboard accountingConciliation',
    path: '/modulesHome/accountingConciliation',
    component: modulesHomeAccountingConciliationPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: false,
    icon: House,
  },
  {
    name: 'Modulos do dashboard payRoll',
    path: '/modulesHome/payRoll',
    component: modulesHomePayRollPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: false,
    icon: House,
  },
  {
    name: 'Modulos do dashboard supervisor',
    path: '/modulesHome/supervisor',
    component: modulesHomeSupervisorPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: false,
    icon: House,
  },
  {
    name: 'Modulos do dashboard supervisorSub',
    path: '/modulesHome/supervisor/sub',
    component: modulesHomeSupervisorSubPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: false,
    icon: House,
  },
  {
    name: 'Modulos do dashboard supervisor regime tributario',
    path: '/modulesHome/supervisor/taxRegime',
    component: ModulesHomeSupervisorTaxRegimePage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: false,
    icon: House,
  },
  {
    name: 'Modulos do dashboard supervisor partner',
    path: '/modulesHome/supervisor/partner',
    component: ModulesHomeSupervisorPartinerPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: false,
    icon: House,
  },
  {
    name: 'Modulos do dashboard supervisor partner companies',
    path: '/supervisor/partner',
    component: undefined,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: ModulesHomeSupervisorPartnerCompaniesPage,
    showSidebar: false,
    icon: ChartBar,
  },
  {
    name: 'Modulos do dashboard simples',
    path: '/modulesHome/supervisor/simples',
    component: modulesHomeSimplesPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: false,
    icon: House,
  },
  {
    name: 'Modulos do dashboard taxPlanning',
    path: '/modulesHome/taxPlanning',
    component: modulesHomeTaxPlanningPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: false,
    icon: House,
  },
  {
    name: 'Segurança',
    path: '/seguranca',
    component: SecurityPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: false,
    isChildrenMenu: false,
  },
  {
    name: 'Perfil',
    path: '/perfil',
    component: ProfilePage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: false,
    isChildrenMenu: false,
  },
  {
    name: 'Sessões',
    path: '/sessoes',
    component: SessionsPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: false,
    isChildrenMenu: false,
  },
  // {
  //   name: 'Clientes',
  //   path: '/clientes',
  //   component: ClientsPage,
  //   componentCreate: ClientsCreatePage,
  //   componentEdit: ClientsEditPage,
  //   componentShow: undefined,
  //   showSidebar: true,
  //   icon: IdentificationBadge,
  // },
  {
    name: 'Configurações',
    path: '/configuracoes',
    isParentMenu: true,
    component: UsersPage,
    componentCreate: UsersCreatePage,
    componentEdit: UsersEditPage,
    componentShow: undefined,
    showSidebar: true,
    icon: GearSix,
  },
  {
    name: 'Sua empresa',
    path: '/configuracoes/sua-empresa',
    component: CompanyPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: true,
    isChildrenMenu: true,
    parentMenu: 'Configurações',
  },
  {
    name: 'Grupos de usuários',
    path: '/configuracoes/grupos',
    component: GroupsPage,
    componentCreate: GroupsCreatePage,
    componentEdit: GroupsEditPage,
    componentShow: undefined,
    showSidebar: true,
    isChildrenMenu: true,
    parentMenu: 'Configurações',
  },
  {
    name: 'Usuários',
    path: '/configuracoes/usuarios',
    component: UsersPage,
    componentCreate: UsersCreatePage,
    componentEdit: UsersEditPage,
    componentShow: undefined,
    showSidebar: true,
    isChildrenMenu: true,
    parentMenu: 'Configurações',
  },
  {
    name: 'Logs de acesso',
    path: '/configuracoes/logs-de-acesso',
    component: UsersPage,
    componentCreate: undefined,
    componentEdit: undefined,
    componentShow: undefined,
    showSidebar: true,
    isChildrenMenu: true,
    parentMenu: 'Configurações',
  },
  {
    name: 'Permissões',
    path: '/configuracoes/permissoes',
    component: PermissionsPage,
    componentCreate: PermissionsCreatePage,
    componentEdit: PermissionsEditPage,
    componentShow: undefined,
    showSidebar: true,
    isChildrenMenu: true,
    parentMenu: 'Configurações',
  },
];
